import './news-widget-item.scss';
import { Link } from 'react-router-dom';
import { Badge } from '../../../common/components/badge/badge';
import {
  truncateByNumber,
  formatDate,
  getMarkdownText,
} from '../../../common/services/helper.service';
import { useTranslation } from 'react-i18next';
import { serveImage } from '../../../common/services/image.sevice';

export const NewsWidgetItem = ({
  strapiBaseUrl,
  heading,
  date,
  itemTextTeaser,
  image,
  category,
  slug,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="widget-news-item-container columns-3 text-sm">
      <div>
        <img
          className="widget-image"
          height="80"
          width="104"
          src={serveImage(image, 'thumbnail')}
          alt={image.alternativeText ? image.alternativeText : ''}
        />
      </div>
      <div className="grow text-left px-2">
        <p className="font-bold truncate heading">
          {truncateByNumber(heading, 60)}
        </p>
        <div className="meta-row">
          <p className="widget-meta-data mb-0.5">{formatDate(date)}</p>
          {category && <Badge text={category} size="small" />}
        </div>
        {itemTextTeaser && (
          <div
            className="content"
            dangerouslySetInnerHTML={getMarkdownText(itemTextTeaser)}
          ></div>
        )}
      </div>
      <div className="flex items-end grow justify-end">
        <Link to={'news/' + slug}>
          <button className="read-more cta-button rounded-full truncate">
            {t('Read more')}
          </button>
        </Link>
      </div>
    </div>
  );
};
