import { NewsWidgetItem } from '../news-widget-item/news-widget-item';
import './news-widget.scss';
import React, { useEffect, useState } from 'react';
import {
  ApiEnum,
  HttpGetWithPagination,
  strapiBaseUrl,
} from '../../../common/services/http.service';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NewsWidget = () => {
  const [posts, setPosts] = useState([]);

  const getPosts = async () => {
    HttpGetWithPagination(ApiEnum.POSTS, 1, 4).then((res) => {
      setPosts(res.data);
    });
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getPosts();
  }, []);

  return (
    <div className="news-container">
      {posts.map((item, i) => {
        return (
          <NewsWidgetItem
            key={i}
            strapiBaseUrl={strapiBaseUrl}
            date={item.attributes.date}
            heading={item.attributes.title}
            itemTextTeaser={item.attributes.content_teaser}
            image={item.attributes.image}
            category={item.attributes.category}
            slug={item.attributes.slug}
          />
        );
      })}
      <div className="news-widget-footer">
        <Link to="news">
          <p className="font-bold text-right">{t('See all the news')}</p>
        </Link>
      </div>
    </div>
  );
};
