const ArrowDown = () => {
  return (
    <>
      <svg
        width="14"
        height="8"
        viewBox="0 0 18 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.60909 -1.90735e-06L0 1.5895L9 10.4211L18 1.58057L16.3909 -1.90735e-06L9 7.25991L1.60909 -1.90735e-06Z"
          fill="black"
        />
      </svg>
    </>
  );
};
export default ArrowDown;
