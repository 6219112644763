import { Tile } from '../../common/components/tile/tile';
import { Carousel } from '../../common/components/carousel/carousel';
import { Footer } from '../../common/components/footer/footer';
import { NewsWidget } from './news-widget/news-widget';
import { homeCarouselItems } from './home-carousel-items';
import { homeNewsItems } from './home-news-items';
import React, { useEffect, useState } from 'react';
import './home.scss';
import { ApplicationLinks } from '../../common/components/application-links/application-links';
import {
  ApiEnum,
  HttpGetWithPopulation,
} from '../../common/services/http.service';

export const Home = () => {
  const [tiles, setTiles] = useState([]);

  const getTiles = async () => {
    HttpGetWithPopulation(ApiEnum.HOMES, [
      'home_tile',
      'home_tile.image',
      'home_tile.button',
    ]).then((res) => {
      setTiles(
        res.data && res.data.length > 0 ? res.data[0].attributes.home_tile : []
      );
    });
  };

  useEffect(() => {
    getTiles();
  }, []);

  return (
    <div className="home-container">
      <Carousel items={homeCarouselItems} />
      <ApplicationLinks />
      <div className="home-content-container">
        <div className="home-left-content-container">
          {tiles &&
            tiles.map((tile, i) => {
              return (
                <Tile
                  key={i}
                  image={tile.image.data}
                  heading={tile.heading}
                  content={tile.content}
                  button={tile.button}
                ></Tile>
              );
            })}
        </div>
        <div className="home-right-content-container">
          <NewsWidget newsItems={homeNewsItems} />
        </div>
      </div>
      <Footer />
    </div>
  );
};
