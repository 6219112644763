export const homeCarouselItems = [
  {
    heading: "First item",
    itemText:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos",
    buttonText: "CTA",
    buttonLink: "https://airtrade.nl",
  },
  {
    heading: "Second item",
    itemText:
      "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo",
    buttonText: "MFA",
    buttonLink: "https://airtrade.nl",
  },
  {
    heading: "Third item",
    itemText:
      "Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat",
    buttonText: "TFA",
    buttonLink: "https://airtrade.nl",
  },
];
