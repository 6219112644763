import './login.scss'
import '../../common/services/http.service'
import React, { useEffect, useState } from 'react'
import { authorize, login } from '../../common/services/auth.service'
import { Button } from '../../common/components/button/button'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/logo.png'
import news from '../../assets/login/news-box.png'
import airlines from '../../assets/login/airlines-box.png'
import tarifs from '../../assets/login/tarifs-box.png'

export const Login = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="login-page">
      <div className="login-page-content">
        <img src={logo} className="login-page-header-logo" alt="logo" />
        <h1>{t('Welcome to Airtrade Extranet')}</h1>
        <p className="login-page-sub-header">
          {t('Travel agents have access to useful information')}
        </p>

        <div className="login-page-boxes">
          <div className="login-page-box">
            <img src={news} alt="" width="200" />
            <strong>{t('News')}</strong>
            <p>{t('Stay tuned')}!</p>
          </div>
          <div className="login-page-box">
            <img src={tarifs} alt="" width="100" />
            <strong>{t('Group bookings')}</strong>
            <p>{t('Request the rate for groups')}</p>
          </div>
          <div className="login-page-box">
            <img src={airlines} alt="" width="100" />
            <strong>{t('Airlines-box')}</strong>
            <p>{t('View all information per airline')}</p>
          </div>
        </div>

        <h2>{t('Login or register now:')}</h2>

        <div className="login-page-buttons">
          <Button
            isRoundedButton={true}
            label={t('Login')}
            customClass="secondary-button"
            onClickAction={() => login()}
          ></Button>
          <Button
            label={'Register'}
            url={'https://www.airtrade.com/contact'}
            external={true}
          />
        </div>
      </div>
    </div>
  )
}
