import './input-box.scss';
import { useState, useEffect } from 'react';
import {
  inputTypes,
  validateDate,
  validateEmail,
  validateName,
  validateNumber,
  validateNumberLength,
} from '../../services/helper.service';
import DatePicker, { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import 'react-datepicker/dist/react-datepicker.css';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';

export const InputBox = ({
  isLeftItem,
  headingText,
  isRequired,
  placeholderText,
  updateFieldValue,
  fieldName,
  fieldType,
  fieldValues = [],
  showRequired,
  isDisabled,
  requiredLength,
  dynamicallyAssigned,
  handleChange,
  independentValue,
  onChangeEvent,
  numberOfRows,
}) => {
  registerLocale('nl', nl);

  const [fieldValue, setFieldValue] = useState('');

  useEffect(() => {
    updateFieldValue(fieldName, '', '', isRequired);
  }, []);

  useEffect(() => {
    if (!fieldValues[fieldName]) {
      setFieldValue('');
    } else {
      if (dynamicallyAssigned) {
        setFieldValue(fieldValues[fieldName]);
      }
    }
  }, [fieldValues]);

  const handleTextChanged = (event) => {
    setFieldValue(event.target.value);
    if (handleChange) {
      handleChange(event.target.value);
    }
    let vaildationText = '';
    switch (fieldType) {
      case inputTypes.date:
        vaildationText = validateDate(event.target.value, isRequired);
        break;
      case inputTypes.email:
        vaildationText = validateEmail(event.target.value, isRequired);
        break;
      case inputTypes.name:
        vaildationText = validateName(event.target.value, isRequired);
        break;
      case inputTypes.number:
        vaildationText = validateNumber(event.target.value, isRequired);
        if (!vaildationText && requiredLength) {
          vaildationText = validateNumberLength(
            event.target.value,
            requiredLength
          );
        }
        break;
    }
    updateFieldValue(fieldName, event.target.value, vaildationText, isRequired);
  };

  const handleDateChanged = (newDate) => {
    setFieldValue(newDate);
    updateFieldValue(fieldName, format(newDate, 'yyyy-MM-dd'), '', isRequired);
  };

  return (
    <div
      className={`input-box-container ${
        showRequired && isRequired && !fieldValue
          ? 'failed-validation-text'
          : ''
      } ${fieldValues[fieldName]?.errorText ? 'input-box-error-spacing' : ''}`}
      style={{
        marginRight: isLeftItem ? 18 : 0,
        marginBottom: fieldValues[fieldName]?.errorText ? 0 : 19,
      }}
    >
      <div className="input-box-heading-container">
        <p className="input-box-heading">{headingText}</p>
        {isRequired && <p className="input-box-asterisk">*</p>}
      </div>
      <div
        className={`input-container ${
          isDisabled ? 'input-container-disabled' : ''
        } ${isLeftItem ? 'input-box-is-left-item' : ''}`}
      >
        {fieldType === inputTypes.date && (
          <label className="date-picker-container">
            <DatePicker
              selected={fieldValue}
              onChange={(date) => handleDateChanged(date)}
              locale={'nl'}
              className="input-control"
              dateFormat={'dd-MM-yyyy'}
              value={fieldValue === '' ? null : fieldValue}
              disabled={isDisabled}
            />
            <FontAwesomeIcon className="input-date-icon" icon={faCalendar} />
          </label>
        )}
        {fieldType !== inputTypes.date &&
          fieldType !== inputTypes.longText &&
          fieldType !== inputTypes.independent && (
            <input
              onChange={(event) => handleTextChanged(event)}
              disabled={isDisabled}
              value={fieldValues[fieldName]?.value ?? ''}
              placeholder={placeholderText}
              className={`input-control ${
                isDisabled ? 'input-container-disabled' : ''
              }`}
              type={
                fieldType === inputTypes.number ||
                fieldType === inputTypes.phoneNumber
                  ? 'number'
                  : 'text'
              }
            />
          )}
        {fieldType === inputTypes.longText && (
          <textarea
            onChange={(event) => handleTextChanged(event)}
            disabled={isDisabled}
            value={fieldValues[fieldName]?.value ?? ''}
            className="input-control"
            cols={1}
            rows={4}
          />
        )}
        {fieldType == inputTypes.independent && !numberOfRows && (
          <input
            onChange={(event) => onChangeEvent(event)}
            disabled={isDisabled}
            value={independentValue}
            placeholder={placeholderText}
            className={`input-control ${
              isDisabled ? 'input-container-disabled' : ''
            }`}
            type={
              fieldType === inputTypes.number ||
              fieldType === inputTypes.phoneNumber
                ? 'number'
                : 'text'
            }
          />
        )}
        {fieldType == inputTypes.independent && numberOfRows && (
          <textarea
            onChange={(event) => onChangeEvent(event)}
            disabled={isDisabled}
            value={independentValue}
            placeholder={placeholderText}
            rows={numberOfRows}
            className={`input-control ${
              isDisabled ? 'input-container-disabled' : ''
            }`}
          />
        )}
      </div>
      {fieldValues.hasOwnProperty(fieldName) && (
        <p className="input-control-error-text">
          {fieldValues.hasOwnProperty(fieldName) &&
            fieldValues[fieldName].errorText}
        </p>
      )}
    </div>
  );
};
