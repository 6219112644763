const gtagEnum = {
  commands: {
    config: "config",
    consent: "consent",
    event: "event",
    get: "get",
    js: "js",
    set: "set"
  },
  types: {
    click: "click",
    conversion: "conversion",
    pageView: "page_view",
    scroll: "scroll"
  },
  indexes: {
    commands: 0,
    types: 1,
    parameters: 2
  },
  urls: {
    gtagjs: "https://www.googletagmanager.com/gtag/js?id="
  }
}

const initGoogleAnalytics = () => {
  const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID;

  if (REACT_APP_GTM_ID) {
    const gaTrackingIds = REACT_APP_GTM_ID.split(",");
    injectGtagScriptElement(gaTrackingIds);
    injectGtagConfigurationElement(gaTrackingIds);
  }
}

const injectGtagScriptElement = (gaTrackingIds) => {
  const scriptsSrc = Array.from(document.getElementsByTagName("script"))
    .map(script => script.attributes.src.textContent);

  gaTrackingIds.forEach(gaTrackingId => {
    if (!checkIfScriptAlreadyExists(scriptsSrc, gaTrackingId)) {
      const scriptElement = document.createElement("script");
      scriptElement.setAttribute("id", `gtagjs-${gaTrackingId}`);
      scriptElement.setAttribute("async", "");
      scriptElement.setAttribute("src", `${gtagEnum.urls.gtagjs}${gaTrackingId}`);
      document.getElementsByTagName("head")[0].appendChild(scriptElement);
    }
  });
}

const injectGtagConfigurationElement = (gaTrackingIds) => {
  if (!document.getElementById("gtagjs-config")) {
    const scriptElement = document.createElement("script");
    scriptElement.setAttribute("id", "gtagjs-config");
    scriptElement.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
      `;

    gaTrackingIds.forEach(gaTrackingId => {
      scriptElement.innerHTML += setGtagConfig(gaTrackingId);
    });

    document.getElementsByTagName("head")[0].appendChild(scriptElement);
  }
}

const setGtagConfig = (gaTrackingId) => {
  return `gtag("${gtagEnum.commands.config}", \"${gaTrackingId}\");`;
}

const checkIfScriptAlreadyExists = (scriptsSrc, gaTrackingId) => {
  return scriptsSrc.find(src => src.includes(gaTrackingId))
    ? true
    : false;
}

export const GoogleAnalyticsService = {
  initGoogleAnalytics: initGoogleAnalytics
};
