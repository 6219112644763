import './carousel-item.scss'
import {
  getMarkdownText,
  truncateByNumber,
  formatDate,
} from '../../../services/helper.service'
import React, { useEffect, useState } from 'react'
import { ApiEnum, HttpGetWithSlug } from '../../../services/http.service'
import { Badge } from '../../badge/badge'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { serveImage } from '../../../services/image.sevice'
import { Button } from '../../button/button'

export const CarouselItem = ({
  header,
  content,
  button,
  background,
  news_slug,
}) => {
  const [post, setPost] = useState([])
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const getPost = async () => {
      HttpGetWithSlug(ApiEnum.POSTS, news_slug).then((res) => {
        if (res[0]) {
          setPost(res[0].attributes)
        }
      })
    }
    if (news_slug.length > 0) {
      getPost()
    }
  }, [news_slug])

  return (
    <div
      className="carousel-item-container"
      style={{
        backgroundImage:
          'url(' +
          serveImage(
            { data: background.data ? background.data : '' },
            'large',
            'large',
            '/generic-carousel.jpg'
          ) +
          ')',
      }}
    >
      <div className="carousel-item-left-container"></div>
      <div className="carousel-item-right-container">
        {!news_slug && (
          <div className="carousel-box">
            <div className="carousel-item-header">{header}</div>

            <div
              className="carousel-item-text"
              dangerouslySetInnerHTML={getMarkdownText(content)}
            />
            {button && (
              <Button label={button.label} url={button.link} external={true} />
            )}
          </div>
        )}
        {post.title && news_slug && (
          <div className="carousel-box">
            <div className="carousel-meta-container">
              <p className="carousel-item-header">
                {truncateByNumber(post.title, 60)}
              </p>
              <div className="carousel-box-meta-data mb-0.5">
                {formatDate(post.date)}{' '}
                {post.category && <Badge text={post.category} />}
              </div>
            </div>

            {post.content_teaser && (
              <div
                className="carousel-item-text"
                dangerouslySetInnerHTML={getMarkdownText(post.content_teaser)}
              ></div>
            )}

            <Button
              label={t('Read more')}
              url={'news/' + news_slug}
              external={false}
            />
          </div>
        )}
      </div>
    </div>
  )
}
