import './group-request-options.tab.scss';
import { useEffect, useState } from 'react';
import { inputTypes } from '../../../common/services/helper.service';
import { t } from 'i18next';
import { GroupOptionCard } from '../../../common/components/group-option-card/group-option-card';
import { Button } from '../../../common/components/button/button';
import { InputBox } from '../../../common/components/input-box/input-box';
import {
  ApiEnum,
  ApiParameters,
  strapiBaseUrl,
  GetGroupBookingOptions,
  HttpGet,
  PostGroupBookingOptions,
} from '../../../common/services/http.service';
import { BaseCheckbox } from '../../../common/components/base-checkbox/base-checkbox';
import { NoticeModal } from '../../../common/components/notice-modal/notice-modal';
import { GroupBookingConfirmationTypes } from '../../../common/enums/group-booking-confirmation-types';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSSR } from 'react-i18next';

export const GroupRequestOptionsTab = ({
  updateFieldValue,
  autoLoadDetails,
}) => {
  const path = 'groepsaanvragens';
  const [lookupAnvr, setLookupAnvr] = useState('');
  const [bookingReference, setBookingReference] = useState('');
  const [comments, setComments] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showInputFields, setShowInputFields] = useState(true);
  const [optionsData, setOptionsData] = useState({});
  const [acceptedGeneralConditions, setAcceptedGeneralConditions] =
    useState(false);
  const [acceptedAirlineConditions, setAirlineConditions] = useState(false);
  const [acceptedTariffConditions, setAcceptedTariffConditions] =
    useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [defaultSelectedOptionId, setDefaultSelectedOptionId] = useState('');
  const [isResultModalShown, setShowResultModal] = useState(false);
  const [resultModalContent, setResultModalContent] = useState({
    heading: '',
    body: '',
    success: false,
  });
  const [validationText, setValidationText] = useState('');
  const [cmsContent, setCmsContent] = useState({});
  const [termsUrl, setTermsUrl] = useState('');
  const [airlineTermsUrl, setAirlineTermsUrl] = useState('');

  useEffect(() => {
    if (bookingReference && lookupAnvr && autoLoadDetails) {
      performLookup();
    }
  }, [bookingReference, lookupAnvr]);

  useEffect(() => {
    const getCmsContent = async () => {
      HttpGet(ApiEnum.GROEPSAANVRAGENS + '?' + ApiParameters.POPULATE).then(
        (res) => {
          if (res.data) {
            setCmsContent(res.data[0].attributes);
            setTermsUrl(
              strapiBaseUrl + res.data[0].attributes.terms.data.attributes.url
            );
            setAirlineTermsUrl(
              strapiBaseUrl +
                res.data[0].attributes.airline_terms.data.attributes.url
            );
          }
        }
      );
    };
    getCmsContent();
  }, [path]);

  useEffect(() => {
    tryToGetSearchValues();
  }, []);

  const tryToGetSearchValues = () => {
    const searchValues = JSON.parse(localStorage.getItem('lookupDetails'));
    if (searchValues) {
      if (searchValues.reference) {
        setLookupAnvr(searchValues.anvr);
        setBookingReference(searchValues.reference);
        if (searchValues.id) {
          setDefaultSelectedOptionId(searchValues.id);
        }
        onSubmitClicked();
      }
    }
  };

  useEffect(() => {
    if (optionsData && defaultSelectedOptionId) {
      selectOption(defaultSelectedOptionId, optionsData.numberOfPersons);
    }
  }, [optionsData]);

  const onSubmitClicked = async () => {
    performLookup();
  };

  const performLookup = async () => {
    localStorage.removeItem('lookupDetails');
    setIsLoading(true);
    const result = await GetGroupBookingOptions(lookupAnvr, bookingReference);
    if (result.data.options) {
      setOptionsData(result.data);
      setShowDetails(true);
      setShowInputFields(false);
    } else if (result.data.errorMessage) {
      setValidationText(result.data.errorMessage);
    } else {
      setValidationText(cmsContent.lookup_booking_not_found_modal_body);
    }
    setIsLoading(false);
  };

  const onResultModalClosed = () => {
    setShowResultModal(false);
    if (validateFieldsForSubmission() && resultModalContent.success) {
      handleHandleSearchAgainClicked();
    }
  };

  const validateFieldsForSubmission = () => {
    if (
      acceptedGeneralConditions &&
      acceptedAirlineConditions &&
      acceptedTariffConditions
    ) {
      if (selectedOptions.length > 0) {
        return true;
      }
      return false;
    }
    return false;
  };

  const submitSelectedOptions = async (confirmationType) => {
    if (validateFieldsForSubmission()) {
      const result = await PostGroupBookingOptions(
        generateConfirmationDto(confirmationType)
      );
      if (result.success) {
        showResultModal(
          cmsContent.finalise_result_modal_success_heading,
          result.data,
          true
        );
      } else {
        showResultModal(
          cmsContent.finalise_result_modal_failed_heading,
          result.data ||
            cmsContent.cmsContent.finalise_result_modal_failed_body,
          false
        );
      }
    } else {
      showResultModal(
        cmsContent.finalise_result_modal_failed_heading,
        cmsContent.finalise_result_modal_validation_message,
        false
      );
    }
  };

  const handleConfirmOptionsClicked = async () => {
    submitSelectedOptions(GroupBookingConfirmationTypes.definite);
  };

  const handleSetInOptionClicked = () => {
    submitSelectedOptions(GroupBookingConfirmationTypes.option);
  };

  const handleGeneralConditionsClicked = () => {
    setAcceptedGeneralConditions(!acceptedGeneralConditions);
  };

  const handleAirlineConditionsClicked = () => {
    setAirlineConditions(!acceptedAirlineConditions);
  };

  const handleTariffConditionsClicked = () => {
    setAcceptedTariffConditions(!acceptedTariffConditions);
  };

  const handleAnvrChanged = (event) => {
    setLookupAnvr(event.target.value);
  };

  const handleBookingReferenceChanged = (event) => {
    setBookingReference(event.target.value);
  };

  const handleCommentsChanged = (event) => {
    setComments(event.target.value);
  };

  const handleHandleSearchAgainClicked = () => {
    setValidationText('');
    setLookupAnvr('');
    setBookingReference('');
    setOptionsData({});
    setShowDetails(false);
    setShowInputFields(true);
  };

  const generateConfirmationDto = (confirmationType) => {
    const cleanedOptions = cleanupSelectedOptions();
    return {
      anvr: lookupAnvr,
      confirmation:
        acceptedAirlineConditions &&
        acceptedGeneralConditions &&
        acceptedTariffConditions,
      reference: bookingReference,
      remark: comments,
      confirmationType: confirmationType,
      choices: cleanedOptions,
    };
  };

  const cleanupSelectedOptions = () => {
    let newSelectedOptions = selectedOptions.slice();
    return newSelectedOptions.filter((x) => x.numberOfPersons > 0);
  };

  const selectOption = (optionId, numberOfPersons) => {
    if (optionId && numberOfPersons > -1) {
      const optionIndex = selectedOptions.findIndex((x) => x.id === optionId);
      let newSelectedOptions = selectedOptions.slice();
      if (optionIndex > -1) {
        newSelectedOptions[optionIndex].numberOfPersons =
          parseInt(numberOfPersons);
      } else {
        newSelectedOptions.push({
          id: optionId,
          numberOfPersons: parseInt(numberOfPersons),
        });
      }
      setSelectedOptions(newSelectedOptions);
    }
  };

  const deselectOption = (optionId) => {
    const newSelectedOptions = selectedOptions.filter((x) => x.id !== optionId);
    setSelectedOptions(newSelectedOptions || []);
  };

  const showResultModal = (heading, body, success = false) => {
    setResultModalContent({
      heading: heading,
      body: body,
      success: success,
    });
    setShowResultModal(true);
  };

  return (
    <div>
      {isResultModalShown && (
        <NoticeModal
          headingText={resultModalContent.heading}
          onClose={onResultModalClosed}
        >
          {resultModalContent.body}
        </NoticeModal>
      )}
      {showInputFields && (
        <div className="splitbox-container">
          <div className="group-request-page-content-container">
            <h3>{t('Mijn groepopties')}</h3>
            <br />
            <span>{cmsContent.booking_lookup_instruction_text}</span>
            <InputBox
              headingText={t('ANVR of klantnummer (alleen cijfers)')}
              isRequired={true}
              independentValue={lookupAnvr}
              updateFieldValue={() => {}}
              onChangeEvent={handleAnvrChanged}
              fieldType={inputTypes.independent}
            />
            <InputBox
              headingText={t('Boekingsreferentienummer')}
              isRequired={true}
              independentValue={bookingReference}
              updateFieldValue={() => {}}
              onChangeEvent={handleBookingReferenceChanged}
              fieldType={inputTypes.independent}
            />
          </div>
          <div className="spacing-container"></div>
        </div>
      )}
      {showInputFields && (
        <div className="group-request-button-container">
          <Button
            isRoundedButton={true}
            label={t('Zoek groepopties')}
            onClickAction={onSubmitClicked}
            isLoading={isLoading}
          />
          {validationText && (
            <FontAwesomeIcon
              className="alert-icon"
              icon={faExclamationCircle}
            />
          )}
          <span className="alert-text">{validationText}</span>
        </div>
      )}
      {optionsData && showDetails && (
        <div className="group-request-booking-details">
          <h3>{t('Groepopties')}</h3>
          <br />
          <span>
            <b>ANVR of klantnummer:</b> {lookupAnvr}
          </span>
          <span>
            <b>Boekingsreferentienummer:</b> {bookingReference}
          </span>
          <div className="group-request-booking-restart-button">
            <Button
              isRoundedButton={true}
              label={t('Zoek opnieuw')}
              onClickAction={handleHandleSearchAgainClicked}
              isLoading={isLoading}
            />
          </div>
          <h4>Groepsinformatie</h4>
          {optionsData && (
            <div className="group-request-booking-details-container">
              <div className="group-request-booking-details-data">
                <span>
                  <b>Naam reisorganisatie: </b>
                  <br />
                  <b>Emailadres: </b>
                  <br />
                  <b>Telefoonnummer: </b>
                  <br />
                  <b>Contactpersoon: </b>
                  <br />
                  <b>Groepsnaam: </b>
                  <br />
                  <b>Aantal personen:</b>
                </span>
                <span className="group-request-booking-details-data-container">
                  {optionsData.groupName}
                  <br />
                  {optionsData.agentEmail}
                  <br />
                  {optionsData.agentPhone}
                  <br />
                  {optionsData.groupContact}
                  <br />
                  {optionsData.groupName}
                  <br />
                  {optionsData.numberOfPersons}
                </span>
              </div>
              <div className="spacing-container"></div>
            </div>
          )}
          <h4>Mijn groepopties</h4>
        </div>
      )}
      {showDetails && optionsData && (
        <div>
          <div className="group-request-page-flight-options">
            {optionsData.options.map((optionDetails, index) => {
              return (
                <GroupOptionCard
                  key={index}
                  optionDetails={optionDetails}
                  optionIndex={index}
                  selectOption={selectOption}
                  deselectOption={deselectOption}
                  numberOfSelectedPassengers={
                    optionDetails.id == defaultSelectedOptionId
                      ? optionsData.numberOfPersons
                      : 0
                  }
                  isDefaultOption={optionDetails.id == defaultSelectedOptionId}
                />
              );
            })}
          </div>

          <div className="group-request-page-lower-container">
            <InputBox
              headingText={t(
                'Opmerkingen (Bijv speciale aanvragen, Optioneel)'
              )}
              independentValue={comments}
              updateFieldValue={() => {}}
              onChangeEvent={handleCommentsChanged}
              fieldType={inputTypes.independent}
              numberOfRows={8}
            />
            <BaseCheckbox
              handleClicked={handleGeneralConditionsClicked}
              isChecked={acceptedGeneralConditions}
            >
              <p>
                Ik verklaar de
                <a href={termsUrl} className="spaced-anchor">
                  algemene groepsvoorwaarden
                </a>
                te hebben gelezen en ga akkoord met de daarin genoemde gegevens.
              </p>
            </BaseCheckbox>
            <BaseCheckbox
              handleClicked={handleAirlineConditionsClicked}
              isChecked={acceptedAirlineConditions}
            >
              <p>
                Ik ga akkoord met
                <a href={airlineTermsUrl} className="spaced-anchor">
                  de specifieke airline groepsvoorwaarden
                </a>
              </p>
            </BaseCheckbox>
            <BaseCheckbox
              handleClicked={handleTariffConditionsClicked}
              isChecked={acceptedTariffConditions}
              labelText={cmsContent.groupdesk_terms_acceptance}
            />
          </div>
          <div className="group-request-page-lower-buttons">
            <Button
              isRoundedButton={true}
              label={t('Definitieve boeking maken')}
              onClickAction={handleConfirmOptionsClicked}
              isLoading={isLoading}
            />
            <Button
              isRoundedButton={true}
              customClass="alternate-button"
              label={t('Boeking in optie zetten')}
              onClickAction={handleSetInOptionClicked}
              isLoading={isLoading}
            />
          </div>
        </div>
      )}
    </div>
  );
};
