import { strapiBaseUrl } from './http.service';

export const serveImage = (
  imageFormatsData,
  requiredimageSize,
  fallbackImageSize,
  specificFallbackImage
) => {
  const fallback = fallbackImageSize === undefined ? null : fallbackImageSize;
  let url;
  switch (requiredimageSize) {
    case 'large':
      url = imageLogic(
        imageFormatsData,
        'large',
        fallback,
        specificFallbackImage
      );
      break;
    case 'medium':
      url = imageLogic(
        imageFormatsData,
        'medium',
        fallback,
        specificFallbackImage
      );
      break;
    case 'small':
      url = imageLogic(
        imageFormatsData,
        'small',
        fallback,
        specificFallbackImage
      );
      break;
    case 'thumbnail':
      url = imageLogic(
        imageFormatsData,
        'thumbnail',
        fallback,
        specificFallbackImage
      );
      break;

    default:
      console.warn(`No image of size ${requiredimageSize}.`);
  }
  return url;
};

const imageLogic = (
  imageFormatsData,
  format,
  fallback,
  specificFallbackImage
) => {
  if (
    imageFormatsData.data &&
    imageFormatsData.data.attributes.formats &&
    imageFormatsData.data.attributes.formats[format] !== undefined
  ) {
    return `${strapiBaseUrl}${imageFormatsData.data.attributes.formats[format].url}`;
  }
  if (
    imageFormatsData.data &&
    imageFormatsData.data.attributes.formats &&
    !imageFormatsData.data.attributes.formats[format] !== undefined &&
    imageFormatsData.data.attributes.formats[fallback]
  ) {
    return `${strapiBaseUrl}${imageFormatsData.data.attributes.formats[fallback].url}`;
  }

  if (
    imageFormatsData.data &&
    !imageFormatsData.data.attributes.formats &&
    imageFormatsData.data.attributes.url
  ) {
    return `${strapiBaseUrl}${imageFormatsData.data.attributes.url}`;
  }

  if (
    imageFormatsData.data &&
    imageFormatsData.data.attributes.formats &&
    !imageFormatsData.data.attributes.url &&
    !imageFormatsData.data.attributes.formats[format] &&
    !imageFormatsData.data.attributes.formats[fallback]
  ) {
    return specificFallbackImage ? specificFallbackImage : '/generic-image.png';
  }

  if (
    !imageFormatsData.data ||
    (!imageFormatsData.data.attributes.formats &&
      !imageFormatsData.data.attributes.url)
  ) {
    return specificFallbackImage ? specificFallbackImage : '/generic-image.png';
  }
};
