import './not-found.scss'
import '../../common/services/http.service'
import React from 'react'
import { Button } from '../../common/components/button/button'
import { useTranslation } from 'react-i18next'
import bg from '../../assets/404/404-bg.png'

export const NotFound = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className="not-found-page">
      <img src={bg} alt="" />
      <div className="not-found-content">
        <h1>404</h1>
        <h2>{t('We couldnt find the page')}</h2>
        <h4>{t('Page does not exist')}</h4>
        <Button label={t('Go back home')} url={'/'} />
      </div>
    </div>
  )
}
