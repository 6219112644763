import './menu-column.scss';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from 'react-router-dom';
import { Logout } from '../logout-button/logout-button';
import { useEffect, useState } from 'react';
import { menuItemLimit } from '../../constants/general';

export const MenuColumn = ({ navigationPaths }) => {
  const [dynamicMenu, setDynamicMenu] = useState([]);

  useEffect(() => {
    if (navigationPaths) {
      setDynamicMenu(navigationPaths);
    }
  }, [navigationPaths]);

  const mapItems = (itemList) => {
    if (!itemList || itemList.length === 0) return [];
    return itemList.map((item) => ({
      label: item.title,
      value: item.path,
      menuAttached: item.menuAttached,
    }));
  };

  return (
    <div className="menu-column-container">
      <Accordion allowZeroExpanded>
        {dynamicMenu.map((menuItem, menuItemIndex) => {
          return (
            menuItemIndex < menuItemLimit &&
            menuItem.menuAttached && (
              <div className="accordion-wrapper" key={menuItemIndex}>
                <AccordionItem key={menuItemIndex}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{menuItem.title}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {mapItems(menuItem?.items).map((item, index) => {
                      return (
                        item.menuAttached && (
                          <Link
                            key={index}
                            to={item.value}
                            className="link-item"
                          >
                            {item.label}
                          </Link>
                        )
                      );
                    })}
                  </AccordionItemPanel>
                </AccordionItem>
              </div>
            )
          );
        })}
      </Accordion>
      <div className="bottom-container">
        <Logout />
      </div>
    </div>
  );
};
