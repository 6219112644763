import "./select-box.scss";
import { useEffect, useState } from "react";
import Select from "react-select";

export const SelectBox = ({
  headingText,
  isRequired,
  listOptions,
  updateFieldValue,
  fieldName,
  fieldValues,
  isMultiSelect,
}) => {
  const [fieldValue, setFieldValue] = useState();

  useEffect(() => {
    if (!fieldValues[fieldName]) {
      setFieldValue([]);
    } else {
      setFieldValue(fieldValues[fieldName].value);
    }
  }, [fieldValues]);

  const ControlStyling = {
    control: (provided) => ({
      ...provided,
      border: "unset",
      margin: "1px",
    }),
  };

  return (
    <div
      style={{
        marginBottom: fieldValues[fieldName]?.errorText ? 0 : 19,
      }}
      className="select-box-container"
    >
      <div className="select-box-heading-container">
        <p className="select-box-heading">{headingText}</p>
        {isRequired && <p className="select-box-asterisk">*</p>}
      </div>
      <div className="select-container">
        <Select
          onChange={(newValue) =>
            updateFieldValue(fieldName, newValue, "", true)
          }
          isMulti={isMultiSelect}
          options={listOptions}
          styles={ControlStyling}
          value={fieldValue}
        />
      </div>
    </div>
  );
};
