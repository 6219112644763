import "./notice-modal.scss";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import { Button } from "../button/button";

export const NoticeModal = ({ children, headingText, onClose }) => {
  const modalStyling = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      width: "400px",
      minHeight: "200px",
      display: "flex",
      flexDirection: "column",
    },
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={true}
      style={modalStyling}
      contentLabel="Example Modal"
    >
      <div className="modal-header-bar">{headingText}</div>
      <div className="modal-content-container">{children}</div>
      <div className="modal-button-container">
        <Button
          isRoundedButton={true}
          label={"Close"}
          onClickAction={onClose}
        />
      </div>
    </Modal>
  );
};
