import './news-list-item.scss'
import { Badge } from '../../../common/components/badge/badge'
import {
  formatDate,
  truncateByNumber,
} from '../../../common/services/helper.service'
import { serveImage } from '../../../common/services/image.sevice'

export const NewsListItem = ({ heading, date, image, category }) => {
  return (
    <div className="news-list-item-container flex-col text-sm">
      <img
        className="object-cover"
        height="277"
        width="277"
        src={serveImage(image, 'thumbnail')}
        alt={image.alternativeText}
      />
      <div className="my-3">
        <Badge text={category} />
      </div>
      <p className="font-bold mb-3"> {truncateByNumber(heading, 60)}</p>
      <p className="meta-data mb-0.5 flex items-end grow">{formatDate(date)}</p>
    </div>
  )
}
