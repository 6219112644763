import './single-airline.scss';
import '../../common/services/http.service';
import React, { useEffect, useState } from 'react';
import {
  ApiEnum,
  HttpGetWithSlug,
  strapiBaseUrl,
} from '../../common/services/http.service';
import { Footer } from '../../common/components/footer/footer';
import { Breadcrumbs } from '../../common/components/breadcrumbs/breadcrumbs';
import 'react-tabs/style/react-tabs.css';
import { TabsAccordion } from '../../common/components/tabs-accordion/tabs-accordion';

export const SingleAirline = () => {
  const path = window.location.pathname.replace('/airlines/', '');
  const [airline, setAirline] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [banner, setBanner] = useState([]);

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  useEffect(() => {
    document.body.classList.add('blue');
    const localeSlug = (slug) => {
      if (path !== slug) {
        window.history.replaceState(null, '', slug);
      }
    };
    const getBanner = (data) => {
      if (
        data[0].attributes.banner_desktop.data ||
        data[0].attributes.banner_mobile.data
      ) {
        setBanner([
          data[0].attributes.banner_mobile.data
            ? `${strapiBaseUrl}${data[0].attributes.banner_mobile.data.attributes.url}`
            : null,
          data[0].attributes.banner_desktop.data
            ? `${strapiBaseUrl}${data[0].attributes.banner_desktop.data.attributes.url}`
            : null,
        ]);
      }
    };

    const getAirline = async () => {
      HttpGetWithSlug(ApiEnum.AIRLINES, path, [
        'banner_desktop, banner_mobile, image, tabs, localizations.image, localizations.banner_desktop, localizations.banner_desktop, localizations.tabs',
      ]).then((res) => {
        localeSlug(res[0].attributes.slug);
        setAirline(res);
        getBanner(res);
      });
    };

    getAirline();

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [path]);

  return (
    <div className="single-airline-page">
      {airline.map((item, i) => {
        return (
          <div className="w-full" key={i}>
            {banner && (
              <div className="airline-banner">
                <div className="airline-banner-wrapper">
                  {dimensions.width <= 919 && banner[0] && (
                    <img className="centered-block" src={banner[0]} alt="" />
                  )}
                  {dimensions.width >= 920 && banner[1] && (
                    <img src={banner[1]} alt="" />
                  )}
                </div>
              </div>
            )}
            <div className="single-airline-wrapper">
              <Breadcrumbs
                stylingClass="pt-6"
                currentItemTitle={item.attributes.name}
                currentItemUrl={item.attributes.slug}
              />
              <h2 className="single-airline-heading">{item.attributes.name}</h2>
              <TabsAccordion data={item} />
            </div>
          </div>
        );
      })}
      <Footer />
    </div>
  );
};
