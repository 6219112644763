import './logout-button.scss';
import { logout } from '../../services/auth.service';

export const Logout = () => {
  return (
    <>
      <button className='logout-btn' onClick={() => logout()}>Logout</button>
    </>
  );
};
