import './single-news.scss'
import { Badge } from '../../common/components/badge/badge'
import '../../common/services/http.service'
import React, { useEffect, useState } from 'react'
import { ApiEnum, HttpGetWithSlug } from '../../common/services/http.service'
import { serveImage } from '../../common/services/image.sevice'
import { Footer } from '../../common/components/footer/footer'
import {
  formatDate,
  getMarkdownText,
} from '../../common/services/helper.service'
import { Breadcrumbs } from '../../common/components/breadcrumbs/breadcrumbs'

export const SingleNews = () => {
  const path = window.location.pathname.replace('/news/', '')
  const [post, setPost] = useState([])

  useEffect(() => {
    const localeSlug = (slug) => {
      if (path !== slug) {
        window.history.replaceState(null, '', slug)
      }
    }

    const getPost = async () => {
      HttpGetWithSlug(ApiEnum.POSTS, path, ['image, localizations.image']).then(
        (res) => {
          localeSlug(res[0].attributes.slug)
          setPost(res)
        }
      )
    }
    getPost()
  }, [path, post.data])

  return (
    <div className="single-news-page">
      {post.map((item, i) => {
        return (
          <div className="single-news-content" key={i}>
            <Breadcrumbs
              stylingClass="pt-6"
              currentItemTitle={item.attributes.title}
              currentItemUrl={item.attributes.slug}
            />
            <div className="single-news" key="{i}">
              <p className="text-left single-news-heading">
                {item.attributes.title}
              </p>
              <div className="single-news-meta-row my-3">
                <p className="meta-data mb-0.5">
                  {formatDate(item.attributes.date)}
                </p>
                {item.attributes.category && (
                  <Badge text={item.attributes.category} size="big" />
                )}
              </div>
              <img
                className="object-cover"
                height="600"
                width="600"
                src={serveImage(item.attributes.image, 'large', 'medium')}
                alt={
                  item.attributes.image.data
                    ? item.attributes.image.data.attributes.alternativeText
                    : ''
                }
              />
              <div
                className="text-left rich-content"
                dangerouslySetInnerHTML={getMarkdownText(
                  item.attributes.content
                )}
              />
            </div>
          </div>
        )
      })}
      <Footer />
    </div>
  )
}
