import { CarouselItem } from './carousel-item/carousel-item'
import { CarouselSelector } from './carousel-selector/carousel-selector'
import './carousel.scss'
import { useEffect, useState } from 'react'
import { ApiEnum, HttpGetWithPopulation } from '../../services/http.service'

export const Carousel = () => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0)
  const [content, setContent] = useState([])
  const [slideInterval, setSlideInterval] = useState('')
  const slideLimit = 5

  const getContent = async () => {
    HttpGetWithPopulation(ApiEnum.CAROUSELS, [
      'slide',
      'slide.background',
      'slide.cta_button',
    ]).then((res) => {
      setContent(res.data[0].attributes)
      setNextSlide(res.data[0].attributes)
    })
  }

  const handleSelectorItemClicked = (itemIndex) => {
    setCurrentItemIndex(itemIndex)
    clearInterval(slideInterval)
  }

  const handleNavigatorClicked = (indexChange) => {
    clearInterval(slideInterval)
    let newItemIndex = currentItemIndex + indexChange
    if (newItemIndex >= content.slide.length || newItemIndex >= slideLimit) {
      newItemIndex = 0
    }
    if (newItemIndex < 0) {
      newItemIndex = content.slide.length - 1
    }

    setCurrentItemIndex(newItemIndex)
  }

  const setNextSlide = (data) => {
    setSlideInterval(
      setInterval(() => {
        setCurrentItemIndex((currIndex) => {
          return currIndex + 1 <= data.slide.length - 1 &&
            currIndex + 1 <= slideLimit - 1
            ? currIndex + 1
            : 0
        })
      }, 5000)
    )
  }

  useEffect(() => {
    getContent()
  }, [])

  return (
    <div className="carousel-container">
      <div className="carousel-upper-container">
        <div
          className="carousel-navigation-button left"
          onClick={() => handleNavigatorClicked(-1)}
        >
          <i className="chevron left icon"></i>
        </div>
        <div className="carousel-content-container">
          {content.slide && (
            <CarouselItem
              header={
                content.slide[currentItemIndex].header
                  ? content.slide[currentItemIndex].header
                  : ''
              }
              content={
                content.slide[currentItemIndex].content
                  ? content.slide[currentItemIndex].content
                  : ''
              }
              button={
                content.slide[currentItemIndex].cta_button
                  ? content.slide[currentItemIndex].cta_button
                  : ''
              }
              background={
                content.slide[currentItemIndex].background
                  ? content.slide[currentItemIndex].background
                  : ''
              }
              news_slug={
                content.slide[currentItemIndex].news_slug
                  ? content.slide[currentItemIndex].news_slug
                  : ''
              }
            />
          )}
        </div>
        <div
          className="carousel-navigation-button right"
          onClick={() => handleNavigatorClicked(1)}
        >
          <i className="chevron right icon"></i>
        </div>
      </div>
      <div className="carousel-lower-container">
        {content.slide &&
          content.slide.slice(0, slideLimit).map((slide, s) => {
            return (
              <CarouselSelector
                handleItemClicked={handleSelectorItemClicked}
                itemIndex={s}
                isSelected={currentItemIndex === s}
                key={s}
              />
            )
          })}
      </div>
    </div>
  )
}
