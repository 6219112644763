import "./App.scss";
import { AirlinesListPage } from "./screens/airlines-page/airlines-list/airlines-list";
import { breakpointsConfig } from "./common/constants/breakpoints-config";
import { environment } from "./common/services/env.service";
import {
  getSessionToken,
  storeLookupParameters,
} from "./common/services/auth.service";
import { GroupRequestPage } from "./screens/group-request-page/group-request-page";
import { Header } from "./common/components/header/header";
import { Home } from "./screens/home/home";
import {
  HttpGetNavigation,
  HttpGetSecondNavigation,
} from "./common/services/http.service";
import { I18nextProvider } from "react-i18next";
import { Login } from "./screens/login/login";
import { MenuColumn } from "./common/components/menu-column/menu-column";
import { NewsListPage } from "./screens/news-page/news-list/news-list";
import { NotFound } from "./screens/not-found/not-found";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { useMedia } from "tiny-use-media";
import i18n from "i18next";
import Menu from "react-burger-menu/lib/menus/slide";
import React, { useEffect, useState } from "react";
import SearchBox from "./common/components/search-box/search-box";
import { PageLoader } from "./common/components/page-loader/page-loader";
import { ResultsPage } from "./screens/results/results";
import { pageTypes } from "./common/enums/page-types";
import { GoogleAnalyticsService } from "./common/services/google-analytics.service";

let breakpoint = null;

function App() {
  breakpoint = useMedia(breakpointsConfig);
  const [routes, setRoutes] = useState([]);
  const [searchParams] = useSearchParams();
  const lang = localStorage.getItem("lang");
  const token = getSessionToken(searchParams);
  const [navigationPaths, setNavigationPaths] = useState([]);
  const [pageIndexes, setPageIndexes] = useState([]);

  useEffect(() => {
    storeLookupParameters();
    getNav();
    GoogleAnalyticsService.initGoogleAnalytics();
  }, []);

  useEffect(() => {}, [navigationPaths]);

  function mapRoutes(nav) {
    return nav
      .map((group) =>
        group.items.map((item) => {
          return { path: item.path, slug: item.related.slug };
        })
      )
      .flat();
  }

  function getNav() {
    const rawNav = [];
    HttpGetNavigation().then((res) => {
      rawNav.push(res);
      HttpGetSecondNavigation().then((res) => {
        rawNav.push(res);
        const [mainNav, pageIndex] = rawNav;
        setPageIndexes(pageIndex);
        setRoutes(mapRoutes(mainNav));
        setNavigationPaths(mainNav);
      });
    });
  }

  const onSearchStub = (searchText) => {
    return;
  };

  if (!token) {
    console.warn(environment());
    return <Login />;
  }

  const getMenuRoutes = () => {
    return routes.map((item, i) => {
      return (
        <Route
          key={i}
          path={`${item.path}`}
          element={<PageLoader pageType={pageTypes.generic} slug={item.slug} />}
        />
      );
    });
  };

  const structureNavigationIndexes = () => {
    if (pageIndexes.length > 0) {
      let newStructure = [];
      for (let i = 0; i < pageIndexes.length; i++) {
        if (pageIndexes[i].related) {
          newStructure.push({
            breadcrumbPath: "",
            path: pageIndexes[i].path.toString().replace("/", ""),
            routePath: pageIndexes[i].path,
            slug: pageIndexes[i].slug,
            parentPage: false,
          });
        } else {
          for (let ii = 0; ii < pageIndexes[i].items.length; ii++) {
            newStructure.push({
              breadcrumbPath: pageIndexes[i].path.toString().replace("/", ""),
              path: pageIndexes[i].path.toString().replace("/", ""),
              routePath: pageIndexes[i].items[ii].path,
              slug: pageIndexes[i].items[ii].related.slug,
              parentPage: true,
            });
          }
        }
      }
      return newStructure;
    }
    return [];
  };

  const getNavigationRoutes = () => {
    return structureNavigationIndexes().map((item, i) => {
      return (
        <Route
          key={i}
          path={item.routePath}
          element={
            <PageLoader
              breadcrumbPath={item.breadcrumbPath}
              path={item.routePath}
              slug={item.slug}
              pageType={pageTypes.generic}
            />
          }
        />
      );
    });
  };

  return (
    <I18nextProvider i18n={i18n}>
      <div className="app" id="outer-container">
        <Header navigationPaths={navigationPaths} />
        {!breakpoint?.xl ? (
          <Menu
            right
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
            routes={routes}
          >
            {!breakpoint?.md ? (
              <SearchBox
                placeholder={"Zoek nieuws…"}
                onSearch={onSearchStub}
                showButton={true}
              />
            ) : (
              ""
            )}
            {navigationPaths.length > 0 && (
              <MenuColumn navigationPaths={navigationPaths} />
            )}
          </Menu>
        ) : (
          ""
        )}

        <main id="page-wrap">
          <Routes>
            <Route path="/" label={lang} element={<Home />} />
            <Route index element={<Home />} />
            <Route path="search" element={<ResultsPage />} />
            <Route
              path="news"
              element={<NewsListPage pageType={pageTypes.post} />}
            />
            <Route
              path="news/*"
              element={<PageLoader pageType={pageTypes.post} />}
            />
            <Route path="airlines/airlines" element={<AirlinesListPage />} />
            <Route path="airlines" element={<AirlinesListPage />} />
            <Route
              path="airlines/*"
              element={<PageLoader pageType={pageTypes.airline} />}
            />
            <Route
              path="aanvragen/groepsaanvragen"
              element={<GroupRequestPage />}
            />
            {routes && getMenuRoutes()}
            {routes && getNavigationRoutes()}
            {routes && routes.length > 0 && (
              <Route path="*" element={<NotFound />} />
            )}
          </Routes>
        </main>
      </div>
    </I18nextProvider>
  );
}

export { breakpoint };
export default App;
