import { serveImage } from "../../services/image.sevice";
import { Button } from "../button/button";
import "./tile.scss";
import { getMarkdownText } from "../../services/helper.service";

export const Tile = ({ heading, image, content, button }) => {
  return (
    <div className="tile-container">
      <div className="tile-upper-content">
        <img
          width="100"
          height="100"
          src={serveImage({ data: image }, "thumbnail")}
          alt=""
        />
        <p className="home-tile-heading">{heading}</p>
        <div
          className="home-tile-content"
          dangerouslySetInnerHTML={getMarkdownText(content)}
        ></div>
      </div>
      <Button
        customClass="mb-5"
        label={button.label}
        onClickAction={button.onClickAction}
        url={button.link}
      />
    </div>
  );
};
