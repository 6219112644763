import './button.scss'
import { Link } from 'react-router-dom'

export const Button = ({
  label,
  url,
  external,
  onClickAction,
  customClass,
  isLoading,
}) => {
  return (
    <div className={customClass}>
      {onClickAction && (
        <button
          disabled={isLoading}
          type="button"
          onClick={onClickAction}
          className="custom-cta cta-button rounded-full truncate"
        >
          {label}
        </button>
      )}
      {!onClickAction && (
        <div className={customClass}>
          {!external && (
            <Link to={url}>
              <button className="custom-cta cta-button rounded-full truncate">
                {label}
              </button>
            </Link>
          )}
          {external && (
            <a href={url}>
              <button className="custom-cta cta-button rounded-full truncate">
                {label}
              </button>
            </a>
          )}
        </div>
      )}
    </div>
  )
}
