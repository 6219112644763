import './group-request-page.scss';
import { useTranslation } from 'react-i18next';
import {
  ApiEnum,
  ApiParameters,
  strapiBaseUrl,
  GenerateGroupBookingInvoice,
  GetGroupBookingCosts,
  HttpGet,
} from '../../common/services/http.service';
import { useEffect, useState } from 'react';
import {
  checkForErrors,
  getMarkdownText,
  inputTypes,
  validateMinMaxDate,
} from '../../common/services/helper.service';
import { WarningBox } from '../../common/components/warning-box/warning-box';
import { InputBox } from '../../common/components/input-box/input-box';
import { SelectBox } from '../../common/components/select-box/select-box';
import { Checkbox } from '../../common/components/checkbox/checkbox';
import { Button } from '../../common/components/button/button';
import { NoticeModal } from '../../common/components/notice-modal/notice-modal';
import { airlinesList } from '../../common/constants/airlines';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Breadcrumbs } from '../../common/components/breadcrumbs/breadcrumbs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  getLookupParametersFromUrl,
  login,
  storeLookupParameters,
} from '../../common/services/auth.service';
import { useSearchParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { InfoBox } from '../../common/components/info-box/info-box';
import { GroupRequestOptionsTab } from './group-request-options-tab/group-request-options-tab';

export const GroupRequestPage = () => {
  const { t, i18n } = useTranslation();
  const path = 'groepsaanvragens';
  const [cmsContent, setCmsContent] = useState({});
  const [costsText, setCostsText] = useState('');
  const [fieldValues, setFieldValues] = useState({});
  const [showRequired, setShowRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState([]);
  const [termsUrl, setTermsUrl] = useState('');
  const [noticeModalProperties, setNoticeModalProperties] = useState({
    headingText: '',
    noticeText: '',
    isVisible: false,
  });
  const [canChangeAnvr, setCanChangeAnvr] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [autoLoadDetails, setAutoLoadDetails] = useState(false);

  useEffect(() => {
    checkForLookupValues();
  }, []);

  const checkForLookupValues = () => {
    const searchValues = JSON.parse(localStorage.getItem('lookupDetails'));
    const fetchedParameters = getLookupParametersFromUrl();
    if (searchValues?.reference || fetchedParameters.reference) {
      setAutoLoadDetails(true);
      setTabIndex(4);
    }
  };

  useEffect(() => {
    lookupTokenDetails();
  }, [canChangeAnvr]);

  const lookupTokenDetails = async () => {
    const sessionToken = sessionStorage.getItem('token');
    if (sessionToken) {
      const decodedToken = jwt_decode(sessionToken);
      if (decodedToken.MoapConfigurationCode) {
        updateFieldValue('anvr', decodedToken.MoapConfigurationCode, '', true);
        setCanChangeAnvr(false);
      } else {
        setCanChangeAnvr(true);
      }
    }
  };

  const fetchCosts = async (anvr) => {
    const costsData = await GetGroupBookingCosts(anvr);
    if (cmsContent.costs_text) {
      setCostsText(
        cmsContent.costs_text.replaceAll('#COSTTAG#', costsData.data.amount)
      );
    }
  };

  const onAnvrChanged = (newValue) => {
    if (newValue) {
      if (newValue.length === 5) {
        fetchCosts(newValue);
      }
    }
  };

  const showNoticeModal = (headingText, noticeText) => {
    setNoticeModalProperties({
      headingText: headingText,
      noticeText: noticeText,
      isVisible: true,
    });
  };

  const handleCloseModalClicked = () => {
    setNoticeModalProperties('', '', false);
  };

  const updateFieldValue = (fieldName, newValue, errorText, isRequired) => {
    if (isRequired) {
      if (requiredFields.indexOf(fieldName) === -1) {
        setRequiredFields((requiredFields) => [...requiredFields, fieldName]);
      }
    }
    let newFieldValues = { ...fieldValues };
    newFieldValues[fieldName] = {
      value: newValue,
      errorText: errorText,
      isRequired: isRequired,
    };
    setFieldValues(newFieldValues);
  };

  const generateDto = () => {
    let dto = {};
    Object.keys(fieldValues).forEach((fieldName) => {
      dto[fieldName] = fieldValues[fieldName].value;
    });

    dto.datePreferences = [
      {
        departureDate: dto.departureDateA,
        returnDate: dto.returnDateA,
      },
    ];

    dto.airlinePreferences = fieldValues.airline.value.map((x) => {
      return {
        airlineCode: x.value,
        airlineName: x.label,
      };
    });

    if (dto.departureDateA && dto.departureDateB) {
      dto.datePreferences.push({
        departureDate: dto.departureDateB,
        returnDate: dto.returnDateB,
      });
    }
    delete dto.airline;

    return dto;
  };

  const isDeclarationChecked = () => {
    if (fieldValues.declaration) {
      if (fieldValues.declaration.value) {
        return true;
      }
    }
    return false;
  };

  const onSubmitClicked = async () => {
    if (
      checkForErrors(fieldValues, requiredFields) ||
      !isDeclarationChecked()
    ) {
      setShowRequired(true);
      showNoticeModal(
        cmsContent.error_modal_heading,
        cmsContent.error_modal_body
      );
    } else if (
      validateMinMaxDate(
        fieldValues?.departureDateA,
        fieldValues?.returnDateA
      ) !== 1 ||
      (fieldValues?.departureDateB &&
        validateMinMaxDate(
          fieldValues?.departureDateB,
          fieldValues?.returnDateB
        ) !== 1)
    ) {
      showNoticeModal(
        cmsContent.error_data_range_modal_heading,
        cmsContent.error_data_range_modal_body
      );
    } else {
      setIsLoading(true);
      const res = await GenerateGroupBookingInvoice(generateDto());
      setIsLoading(false);
      if (res.success && res.data.invoiceReference) {
        setFieldValues({});
        setCanChangeAnvr(true);
        setShowRequired(false);
        showNoticeModal(
          cmsContent.success_modal_heading,
          cmsContent.success_modal_body.replace(
            '#referenceNumber#',
            res.data?.invoiceReference
          )
        );
      } else if (res.success && !res.data.invoiceReference) {
          showNoticeModal("Error: ", res.data.message.replace("Error: ", ''));
      } else {
        showNoticeModal(t('Error'), t('Error submitting form'));
      }
    }
  };

  const formatAirlineList = () => {
    return airlinesList.map((airline) => {
      return {
        label: airline.DisplayName,
        value: airline.Code,
      };
    });
  };

  useEffect(() => {
    const getCmsContent = async () => {
      HttpGet(ApiEnum.GROEPSAANVRAGENS + '?' + ApiParameters.POPULATE).then(
        (res) => {
          if (res.data) {
            setCmsContent(res.data[0].attributes);
            setTermsUrl(
              strapiBaseUrl + res.data[0].attributes.terms.data.attributes.url
            );
          }
        }
      );
    };
    getCmsContent();
  }, [path]);

  useEffect(() => {
    if (cmsContent && fieldValues.anvr) {
      fetchCosts(fieldValues.anvr.value);
    }
  }, [cmsContent]);

  return (
    <div className="group-request-page-container">
      {noticeModalProperties.isVisible && (
        <NoticeModal
          headingText={noticeModalProperties.headingText}
          onClose={handleCloseModalClicked}
        >
          {noticeModalProperties.noticeText}
        </NoticeModal>
      )}
      <div className="group-request-heading-container pt-6">
        <Breadcrumbs
          stylingClass="pt-2 w-full"
          currentItemTitle="Groepsaanvraagen"
          currentItemUrl="/groepsaanvragen"
        />
        {cmsContent.instructions && (
          <p className="page-heading mt-6">{t('Groepsaanvragen')}</p>
        )}
      </div>
      <div className="page-content-container">
        {cmsContent.instructions && (
          <Tabs
            className="group-request-tab-heading"
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList>
              <Tab>
                <i className="info circle icon"></i>
                Informatie
              </Tab>
              <Tab>
                <i className="people group solid icon"></i>
                Aanvragen
              </Tab>
              <Tab>
                <i className="list icon"></i>
                Namenlijst
              </Tab>
              <Tab>
                <i className="question circle icon"></i>
                FAQ
              </Tab>
              <Tab>
                <i className="file circle icon"></i>
                Mijn groepopties
              </Tab>
            </TabList>
            <TabPanel>
              <div
                dangerouslySetInnerHTML={getMarkdownText(
                  cmsContent.informatie_tab
                )}
              ></div>
            </TabPanel>
            <TabPanel>
              {cmsContent.instructions && (
                <div>
                  <div className="splitbox-container">
                    <div className="group-request-page-content-container">
                      <p className="page-subheading">
                        {t('Groepsboeking aanvragen')}
                      </p>
                      <p
                        dangerouslySetInnerHTML={getMarkdownText(
                          cmsContent.instructions
                        )}
                      ></p>
                      <WarningBox warningText={cmsContent.warning_text} />
                      <div className="splitbox-parent">
                        <p className="page-subheading">
                          {t('1. Reis informatie')}
                        </p>
                        <p className="base-colour-text">
                          {t('* Vereiste gegevens')}
                        </p>
                      </div>
                      <div className="splitbox-parent">
                        <InputBox
                          headingText={t('Vanaf')}
                          isLeftItem={true}
                          isRequired={true}
                          updateFieldValue={updateFieldValue}
                          fieldName="origin"
                          fieldValues={fieldValues}
                          fieldType={inputTypes.name}
                          showRequired={showRequired}
                        />
                        <InputBox
                          headingText={t('Naar')}
                          isRequired={true}
                          updateFieldValue={updateFieldValue}
                          fieldName="destination"
                          fieldValues={fieldValues}
                          fieldType={inputTypes.name}
                          showRequired={showRequired}
                        />
                      </div>
                      <div className="splitbox-parent">
                        <InputBox
                          headingText={t('Heendatum A')}
                          isLeftItem={true}
                          isRequired={true}
                          updateFieldValue={updateFieldValue}
                          fieldName="departureDateA"
                          fieldValues={fieldValues}
                          fieldType={inputTypes.date}
                          showRequired={showRequired}
                        />
                        <InputBox
                          headingText={t('Retourdatum A')}
                          isRequired={true}
                          updateFieldValue={updateFieldValue}
                          fieldName="returnDateA"
                          fieldValues={fieldValues}
                          fieldType={inputTypes.date}
                          showRequired={showRequired}
                        />
                      </div>
                      <div className="splitbox-parent">
                        <InputBox
                          headingText={t('Heendatum B')}
                          isLeftItem={true}
                          updateFieldValue={updateFieldValue}
                          fieldName="departureDateB"
                          fieldValues={fieldValues}
                          fieldType={inputTypes.date}
                          showRequired={showRequired}
                        />
                        <InputBox
                          headingText={t('Retourdatum B')}
                          updateFieldValue={updateFieldValue}
                          fieldName="returnDateB"
                          fieldValues={fieldValues}
                          fieldType={inputTypes.date}
                          showRequired={showRequired}
                        />
                      </div>
                      <SelectBox
                        headingText={t('Airline')}
                        isLeftItem={true}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="airline"
                        fieldValues={fieldValues}
                        showRequired={showRequired}
                        listOptions={formatAirlineList()}
                        isMultiSelect={true}
                      />
                      <p className="page-subheading">
                        {t('2. Groepsgegevens')}
                      </p>
                      <InputBox
                        headingText={t('Groepsnaam')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="groupName"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.name}
                        showRequired={showRequired}
                      />
                      <InputBox
                        headingText={t('Groepstype')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="groupType"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.name}
                        showRequired={showRequired}
                      />
                      <InputBox
                        headingText={t('Aantal personen')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="numberOfPassengers"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.number}
                        showRequired={showRequired}
                      />
                      <InputBox
                        headingText={t('Budget per person')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="budget"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.number}
                        showRequired={showRequired}
                      />
                      <p className="page-subheading">
                        {t('3. Contactgegevens')}
                      </p>
                      <InputBox
                        headingText={t('Naam reisorganisatie')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="agencyName"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.name}
                        showRequired={showRequired}
                      />
                      <InputBox
                        headingText={t('ANVR of klantnummer (alleen cijfers)')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="anvr"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.number}
                        showRequired={showRequired}
                        isDisabled={!canChangeAnvr}
                        requiredLength={5}
                        dynamicallyAssigned={true}
                        handleChange={onAnvrChanged}
                      />
                      <InputBox
                        headingText={t('Contactpersoon')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="contact"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.name}
                        showRequired={showRequired}
                      />
                      <InputBox
                        headingText={t('Telefoonnummer')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="phone"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.phoneNumber}
                        showRequired={showRequired}
                      />
                      <InputBox
                        headingText={t('Emailadres')}
                        isRequired={true}
                        updateFieldValue={updateFieldValue}
                        fieldName="email"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.email}
                        showRequired={showRequired}
                      />
                      <InputBox
                        headingText={t(
                          'Opmerking (Bijv. speciale aanvragen, Optioneel)'
                        )}
                        isRequired={false}
                        updateFieldValue={updateFieldValue}
                        fieldName="comments"
                        fieldValues={fieldValues}
                        fieldType={inputTypes.longText}
                        showRequired={showRequired}
                      />
                      <Checkbox
                        isRequired={true}
                        isManaged={true}
                        onValueChanged={updateFieldValue}
                        showRequired={showRequired}
                        fieldName={'declaration'}
                        fieldValues={fieldValues}
                      >
                        <p>{t('Ik verklaar de')}</p>
                        <a href={termsUrl} className="spaced-anchor">
                          {t('algemene groepsvoorwaarden')}
                        </a>
                        <p>{t('te hebben gelezen en ')}</p>
                        <p>
                          {t('ga akkoord met de daarin genoemde gegevens.')}
                        </p>
                      </Checkbox>
                      {costsText && <InfoBox infoText={costsText} />}
                      <div className="proceed-button-box">
                        <Button
                          isRoundedButton={true}
                          label={t('Vezend groepsaanvraag')}
                          onClickAction={onSubmitClicked}
                          isLoading={isLoading}
                        />
                      </div>
                    </div>
                    <div className="spacing-container"></div>
                  </div>
                </div>
              )}
            </TabPanel>
            <TabPanel>
              <div
                dangerouslySetInnerHTML={getMarkdownText(
                  cmsContent.namenlijst_tab
                )}
              ></div>
            </TabPanel>
            <TabPanel>
              <div>
                <Accordion allowZeroExpanded>
                  {cmsContent.faq_questions.map((item, i) => {
                    return (
                      <AccordionItem key={i}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            {item.Heading}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel
                          dangerouslySetInnerHTML={getMarkdownText(
                            item.Panel_content
                          )}
                        ></AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
            </TabPanel>
            <TabPanel>
              <GroupRequestOptionsTab
                autoLoadDetails={autoLoadDetails}
                updateFieldValue={updateFieldValue}
              />
            </TabPanel>
          </Tabs>
        )}
      </div>
    </div>
  );
};
