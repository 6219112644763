import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './base-checkbox.scss';

export const BaseCheckbox = ({
  handleClicked,
  isChecked,
  labelText,
  children,
}) => {
  return (
    <div className="base-checkbox">
      <div className="checkbox-control-container" onClick={handleClicked}>
        {isChecked && (
          <FontAwesomeIcon icon={faCheck} className="checkbox-icon" />
        )}
      </div>
      {labelText && labelText}
      {!labelText && <div className="checkbox-children">{children}</div>}
    </div>
  );
};
