import { t } from 'i18next';
import { useState } from 'react';
import { inputTypes, TextToMarkup } from '../../services/helper.service';
import { BaseCheckbox } from '../base-checkbox/base-checkbox';
import { InputBox } from '../input-box/input-box';
import { NumberStepper } from '../number-stepper/number-stepper';
import './group-option-card.scss';

export const GroupOptionCard = ({
  optionDetails,
  optionIndex,
  selectOption,
  deselectOption,
  numberOfSelectedPassengers,
  isDefaultOption,
}) => {
  const [numberOfPassengers, setNumberOfPassengers] = useState(
    numberOfSelectedPassengers
  );
  const [isChecked, setIsChecked] = useState(isDefaultOption);

  const handleCheckboxClicked = () => {
    const newCheckedValue = !isChecked;
    if (newCheckedValue) {
      selectOption(optionDetails.id, numberOfPassengers);
    } else {
      deselectOption(optionDetails.id);
    }
    setIsChecked(newCheckedValue);
  };

  const handlePassengerCountChanged = (newValue) => {
    const newPassengerCount = newValue;
    setNumberOfPassengers(newPassengerCount);
    if (newPassengerCount < 1) {
      setNumberOfPassengers(0);
      if (isChecked) {
        deselectOption(optionDetails.id);
      }
    } else {
      if (isChecked) {
        selectOption(optionDetails.id, newPassengerCount);
      }
    }
  };

  return (
    <div className="group-option-card-container">
      <b>Optie {optionIndex + 1}</b>
      <div
        className={`group-option-card-heading ${
          isChecked ? 'group-option-box-selected' : 'group-option-box'
        }`}
      >
        {optionDetails.airlineName}
        <BaseCheckbox
          isChecked={isChecked}
          handleClicked={handleCheckboxClicked}
        />
      </div>
      {optionDetails.route && (
        <div className="group-option-card-content">
          <span>
            {' '}
            {t('Prijs per volwassene: ')}
            {parseFloat(optionDetails.price).toFixed(2)}
          </span>
          <span>
            {t('Luchthavenbelasting: ')}{' '}
            {parseFloat(optionDetails.tax).toFixed(2)}
          </span>
          <br />
          {optionDetails.route}
          <br />
          <br />
          <div className="spacing-container"></div>
          <b>Aantal personen</b>
          <NumberStepper
            currentValue={numberOfPassengers}
            setCurrentValue={handlePassengerCountChanged}
          />
        </div>
      )}
    </div>
  );
};
