import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import "./warning-box.scss";
import { getMarkdownText } from "../../services/helper.service";

export const WarningBox = ({ warningText }) => {
  return (
    <div className="warning-box-container">
      <div className="warning-box-icon">
        <FontAwesomeIcon icon={faWarning} />
      </div>
      <div
        className="warning-box-content"
        dangerouslySetInnerHTML={getMarkdownText(warningText)}
      ></div>
    </div>
  );
};
