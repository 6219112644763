export const applicationLinksItem = [
  {
    url: "https://ff.airtradedev.com/login",
    needAuth: true,
    logo: "logos/ff_logo.png",
    title: "Fare**finder**",
    color: "primary",
  },
  {
    url: "https://my.farefinder.nl/",
    needAuth: false,
    logo: "logos/ff_logo.png",
    title: "My **Fare**finder",
    color: "primary",
  },
];

export const prodApplicationLinksItem = [
  {
    url: "https://www.farefinder.nl/login",
    needAuth: true,
    logo: "logos/ff_logo.png",
    title: "Fare**finder**",
    color: "primary",
  },
  {
    url: "https://my.farefinder.nl/",
    needAuth: false,
    logo: "logos/ff_logo.png",
    title: "My **Fare**finder",
    color: "primary",
  },
];
