import {useEffect, useState} from 'react';
import './search-box.scss';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export const SearchBox = ({className, placeholder, showButton, onSaveTextInput, disableButton }) => {
  const [searchFieldText, setSearchFieldText] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isButtonDisabled, setIsDisabledButton] = useState(false);

  useEffect(()=> {
    if(disableButton){
      setIsDisabledButton(true);
    }
  },[]);


  const handleSearchFieldTextChanges = (event) => {
    const value = event.target.value
    if (value.match('^[a-zA-Z ]*$') != null) {
      setSearchFieldText(value);
      setIsActive(true);
    }

    if (event.target.value === '') {
      setIsActive(false);
    }
    if (onSaveTextInput) {
      onSaveTextInput(value);
    }
  };

  return (
    <div className={'search-box-container ' + (className ? className : '')}>
      <div className="search-box-input-container">
        <input
          placeholder={placeholder}
          onChange={handleSearchFieldTextChanges}
          className="px-2"
          pattern="[A-Za-z]{3}"
          maxLength={20}
        />
        {showButton && (
          <Link
            to={
              isActive && !isButtonDisabled
                ? { pathname: 'search', search: `?query=${searchFieldText} ` }
                : { pathnames: '#' }
            }
          >
            <div className="search-box-button">
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
