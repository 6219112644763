import axios from 'axios';
import { GetEnvironmentSettings, isProduction } from './env.service';

const qs = require('qs');

export const strapiBaseUrl = 'https://strapi.airtrade.com';
const strapiBaseAPIUrl = 'https://strapi.airtrade.com/api/';

const extranetApiKey = '961e9436-ba76-4cab-ac85-443a5e2415e3';

const languages = {
  en: 'en',
  nl: 'nl-NL',
};

const language = languages[localStorage.getItem('lang')];

// JSX way of creating an enum

export const ApiEnum = {
  POSTS: 'posts',
  POST: 'post',
  AIRLINES: 'airlines',
  AIRLINE: 'airline',
  FOOTER: 'footer',
  FOOTERS: 'footers',
  CAROUSEL: 'carousel',
  CAROUSELS: 'carousels',
  HOME: 'home',
  HOMES: 'homes',
  GROEPSAANVRAGENS: 'groepsaanvragens',
  PAGES: 'pages',
};

export const ApiParameters = {
  POPULATE: 'populate=*',
};

Object.freeze(ApiEnum);

export const HttpGetWithSorting = (api, sortMethod) => {
  const query = qs.stringify(
    {
      sort: [sortMethod],
      populate: '*',
      locale: language,
      pagination: {
        page: 1,
        pageSize: 250,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return axios
    .get(strapiBaseAPIUrl + api + '?' + query)
    .then((res) => res.data)
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

export const HttpGetWithPagination = (api, page, pageSize) => {
  const query = qs.stringify(
    {
      sort: ['date:desc'],
      populate: 'image',
      locale: language,
      pagination: {
        page: page,
        pageSize: pageSize,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );

  return axios
    .get(strapiBaseAPIUrl + api + '?' + query)
    .then((res) => res.data)
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

export const HttpGetWithPopulation = (api, populateArray) => {
  const query = qs.stringify(
    {
      populate: populateArray,
      locale: language,
    },
    {
      encodeValuesOnly: true,
    }
  );

  return axios
    .get(strapiBaseAPIUrl + api + '?' + query)
    .then((res) => res.data)
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

export const HttpGetWithSlug = (api, slug, populateArray) => {
  const query = qs.stringify(
    {
      populate: populateArray,
      locale: 'all',
      filters: {
        slug: {
          $eq: slug,
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return axios
    .get(strapiBaseAPIUrl + api + '?' + query)
    .then((res) => {
      if (res.data) {
        if (
          res.data.data[0] &&
          res.data.data[0].attributes.locale === language
        ) {
          return res.data.data;
        } else if (
          res.data.data[0] &&
          res.data.data[0].attributes.localizations.data
        ) {
          return res.data.data[0].attributes.localizations.data;
        } else {
          return res.data.data;
        }
      }
    })
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

export const GenerateGroupBookingInvoice = async (fieldValues) => {
  try {
    const result = await axios.post(
      GetEnvironmentSettings().apiUrl +
        `/groupbooking/generate-invoice?apiKey=${extranetApiKey}`,
      fieldValues
    );
    return {
      success: true,
      data: result.data,
    };
  } catch (ex) {
    return {
      success: false,
    };
  }
};

export const GetGroupBookingOptions = async (anvr, bookingReference) => {
  try {
    const result = await axios.get(
      `${
        GetEnvironmentSettings().apiUrl
      }/groupbooking/get-group-booking-options?anvr=${anvr}&bookingReference=${bookingReference}&apiKey=${extranetApiKey}`
    );
    return {
      success: true,
      data: result.data,
    };
  } catch (ex) {
    return {
      success: false,
    };
  }
};

export const GetGroupBookingCosts = async (anvr) => {
  try {
    const result = await axios.get(
      `${
        GetEnvironmentSettings().apiUrl
      }/groupbooking/get-costs?anvr=${anvr}&apiKey=${extranetApiKey}`
    );
    return {
      success: true,
      data: result.data,
    };
  } catch (ex) {
    return {
      success: false,
    };
  }
};

export const PostGroupBookingOptions = async (selectedOptionsDto) => {
  try {
    const result = await axios.post(
      `${
        GetEnvironmentSettings().apiUrl
      }/groupbooking/confirm-booking-options?apiKey=${extranetApiKey}`,
      selectedOptionsDto
    );
    return {
      success: true,
      data: result.data,
    };
  } catch (ex) {
    return {
      success: false,
    };
  }
};

export const HttpGetNavigation = () => {
  return axios
    .get(strapiBaseAPIUrl + 'navigation/render/1?type=tree')
    .then((res) => res.data)
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

export const HttpGetSecondNavigation = () => {
  return axios
    .get(strapiBaseAPIUrl + 'navigation/render/2?type=tree')
    .then((res) => res.data)
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

export const Search = (query) => {
  return axios
    .get(
      `${
        GetEnvironmentSettings().apiUrl
      }/cms/search-posts?searchText=${query}&apiKey=${extranetApiKey}`
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

/* Basic HTTP handlers */

export const HttpGet = (api) => {
  return axios
    .get(strapiBaseAPIUrl + api + `&locale=${language}`)
    .then((res) => res.data)
    .catch((error) => {
      console.error('HttpGet', error);
    });
};

export const HttpPost = (api, payload) => {
  axios
    .post(strapiBaseAPIUrl + api, payload)
    .then((res) => res.data.data)
    .catch((error) => {
      console.error('HttpPost', error);
    });
};

export const HttpPut = (api) => {
  axios
    .put(strapiBaseAPIUrl + api)
    .then((res) => res.data.data)
    .catch((error) => {
      console.error('HttpPut', error);
    });
};

export const HttpDelete = (api) => {
  axios
    .delete(strapiBaseAPIUrl + api)
    .then((res) => res.data.data)
    .catch((error) => {
      console.error('HttpDelete', error);
    });
};
