import { Link } from 'react-router-dom'
import './breadcrumbs.scss'

export const Breadcrumbs = ({
  currentItemUrl,
  currentItemTitle,
  stylingClass,
  nonClickableMiddleSection = false,
  path
}) => {
  const url = window.location.pathname
  const depth = function () {
    return (url.match(/\//g) || []).length
  }

  const middleSection = function () {
    return path ?? url.split('/')[1]
  }

  const humanize = function (str) {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase()
      })
  }

  return (
    <div className={'breadcrumbs ' + stylingClass}>
      <Link to="/">Home</Link>
      <span className="separator"> › </span>
      {depth() === 2 && (
        <span>
          {!nonClickableMiddleSection && (
            <Link to={'/' + middleSection()}>{humanize(middleSection())}</Link>
          )}
          {nonClickableMiddleSection && (
            <span>{humanize(middleSection())}</span>
          )}
          <span className="separator"> › </span>
        </span>
      )}
      <Link className="breadcrumbs_active" to={url}>
        {currentItemTitle}
      </Link>
    </div>
  )
}
