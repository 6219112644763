import './results.scss'
import React, { useEffect, useState } from 'react'
import { useSearchParams, Link } from 'react-router-dom'
import { Search } from '../../common/services/http.service'
import { useTranslation } from 'react-i18next'
import {
  getMarkdownText,
  formatDate,
} from '../../common/services/helper.service'

export const ResultsPage = () => {
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const [results, setResults] = useState([])
  const [amount, setAmount] = useState([])

  const sortByDate = (arr) => {
    return arr.sort(function (a, b) {
      return new Date(b.datePublished) - new Date(a.datePublished)
    })
  }

  useEffect(() => {
    document.body.classList.add('blue')
    Search(searchParams.get('query')).then((res) => {
      if (res && res.results) {
        setResults(sortByDate(res.results))
        setAmount(res.numberOfItems)
      }
    })
  }, [searchParams])

  return (
    <div>
      <div className="results-container">
        <div className="results-wrapper">
          <h1>
            {amount}
            {t('Search results for')}
          </h1>
          <h1 className="results-query">{searchParams.get('query')}</h1>
          {results &&
            results.map((item, i) => {
              return (
                <Link
                  key={i}
                  to={`/news/${item.slug}`}
                  replace
                  className="results-link"
                >
                  <div className="results-content">
                    <h4>{item.title}</h4>
                    <p className="results-date">
                      {formatDate(item.datePublished)}
                    </p>
                    <div
                      className="results-snippet"
                      dangerouslySetInnerHTML={getMarkdownText(
                        item.snippetText
                      )}
                    />
                  </div>
                </Link>
              )
            })}
        </div>
      </div>
    </div>
  )
}
