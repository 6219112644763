import './application-links-button.scss'
import { getMarkdownText } from '../../../services/helper.service'
import { convertLangToCulture } from '../../../services/helper.service'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { getTokenFromApi } from '../../../services/auth.service'
import { useEffect, useState } from 'react'

export const ApplicationLinksButton = ({
  url,
  needAuth,
  logo,
  title,
  color,
}) => {
  const [ready, setReady] = useState(false)

  const embedBearerToken = (url, needAuth) => {
    if (needAuth) {
      const bearer_token = JSON.parse(sessionStorage.getItem('token'))
      if (bearer_token) {
        return `${url}?bearer_token=${bearer_token}&culture_code=${convertLangToCulture(
          localStorage.getItem('lang')
        )}`
      }
    } else {
      return url
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => setReady(true), 2500)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="application-links-button-container">
      {ready && (
        <a
          href={embedBearerToken(url, needAuth)}
          target="_blank"
          rel="noreferrer"
        >
          <div className="application-links-button">
            <img src={`/${logo}`} alt="" height="30" width="30" />
            <div
              className={color}
              dangerouslySetInnerHTML={getMarkdownText(title)}
            />
          </div>
        </a>
      )}
    </div>
  )
}
