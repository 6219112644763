import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import "./info-box.scss";
import { getMarkdownText } from "../../services/helper.service";

export const InfoBox = ({ infoText }) => {
  return (
    <div className="info-box-container">
      <div className="info-box-icon">
        <FontAwesomeIcon icon={faInfoCircle} />
      </div>
      <div
        className="info-box-content"
        dangerouslySetInnerHTML={getMarkdownText(infoText)}
      ></div>
    </div>
  );
};
