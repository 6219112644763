import './generic-page.scss';
import React, { useEffect, useState } from 'react';
import { ApiEnum, HttpGetWithSlug } from '../../common/services/http.service';
import { Breadcrumbs } from '../../common/components/breadcrumbs/breadcrumbs';
import { Footer } from '../../common/components/footer/footer';
import { getMarkdownText } from '../../common/services/helper.service';
import { serveImage } from '../../common/services/image.sevice';
import { TabsAccordion } from '../../common/components/tabs-accordion/tabs-accordion';

export const GenericPage = ({ urlPath, slug, breadcrumbPath }) => {
  const newPath = urlPath ?? window.location.pathname.split('/').pop();
  const [page, setPage] = useState([]);
  const [pageContent, setPageContent] = useState({});

  useEffect(() => {
    document.body.classList.add('blue');
    const localeSlug = (slug) => {};

    const getPage = async () => {
      HttpGetWithSlug(ApiEnum.PAGES, slug, [
        'header_image',
        'localizations',
        'localizations.header_image',
        'tabs',
        'localizations.tabs',
      ]).then((res) => {
        if (res && res[0]) {
          if (res[0].attributes && res[0].attributes.slug) {
            localeSlug(res[0].attributes.slug);
          }
          setPage(res);
          setPageContent(res[0]);
        }
      });
    };
    if (slug) {
      getPage();
    }
  }, [newPath, slug]);

  return (
    <div>
      {pageContent.attributes && (
        <div className="generic-page-container">
          <div className="generic-page-wrapper">
            <Breadcrumbs
              stylingClass="pt-2 w-full"
              currentItemTitle={pageContent.attributes.title}
              currentItemUrl={slug}
              path={breadcrumbPath}
              nonClickableMiddleSection={true}
            />
            <div className="generic-page-header">
              {pageContent.attributes.title && (
                <p className="page-heading mt-6">
                  {pageContent.attributes.title}
                </p>
              )}
              {pageContent.attributes.header_image &&
                pageContent.attributes.header_image.data && (
                  <img
                    className="object-cover"
                    height="200"
                    width="200"
                    src={serveImage(
                      pageContent.attributes.header_image,
                      'medium',
                      'small'
                    )}
                    alt=""
                  />
                )}
            </div>

            {pageContent.attributes.rich_content && (
              <div
                className="text-left rich-content"
                dangerouslySetInnerHTML={getMarkdownText(
                  pageContent.attributes.rich_content
                )}
              />
            )}

            {pageContent.attributes.tabs.length > 0 && (
              <TabsAccordion data={pageContent} />
            )}
          </div>
          {pageContent.attributes.footer && <Footer />}
        </div>
      )}
    </div>
  );
};
