import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en-GB.json"
import translationNL from "./nl-NL.json"

const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  }
};


const LANG = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'nl'
if (!localStorage.getItem('lang')) {
  localStorage.setItem('lang', LANG)
}


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: LANG,
    supportedLngs: ['nl', 'en'],
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });


  export default i18n;
