import './carousel-selector.scss'

export const CarouselSelector = ({
  isSelected,
  itemIndex,
  handleItemClicked,
}) => {
  return (
    <div
      onClick={() => handleItemClicked(itemIndex)}
      className="carousel-selector"
      style={{ opacity: isSelected ? '1' : '0.5' }}
    ></div>
  )
}
