import { marked } from 'marked';
import { strapiBaseUrl } from './http.service';
import isMatch from 'date-fns/isMatch';
import { compareDesc, parseISO } from 'date-fns';

export const inputTypes = {
  name: 'name',
  email: 'email',
  date: 'date',
  number: 'number',
  phoneNumber: 'phoneNumber',
  longText: 'longText',
  independent: 'independent',
};

export const TextToMarkup = (incomingText) => {
  return incomingText.replaceAll('\r', '<br/>');
};

const correctDateFormat = 'yyyy-MM-dd';

export const truncateByNumber = function (str, number) {
  return str.length > number ? str.substring(0, number) + '...' : str;
};

export const formatDate = function (dateStr) {
  return new Intl.DateTimeFormat('nl-NL', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: 'UTC',
  })
    .formatToParts(new Date(dateStr))
    .map(({ type, value }) => {
      switch (type) {
        case 'literal':
          return value !== ' ' ? value : ' | ';
        default:
          return value;
      }
    })
    .join('');
};

export const getMarkdownText = function (content) {
  const rawMarkup = marked.parse(content);
  const modifiedMarkup = rawMarkup
    .replaceAll('<img src="/', `<img src="${strapiBaseUrl}/`)
    .replaceAll('style="font-family:Arial, Helvetica, sans-serif;"', '')
    .replaceAll(
      '<input type="checkbox" disabled="disabled"',
      '<input type="checkbox"'
    );

  return {
    __html: modifiedMarkup,
  };
};

export const validateEmail = (inputText) => {
  const validRegex = /\S+@\S+\.\S+/;
  return validRegex.test(inputText) ? '' : 'Invalid email address';
};

export const validateDate = (inputText) => {
  return isMatch(inputText, correctDateFormat)
    ? ''
    : `Invalid date, date should be in this format ${correctDateFormat}`;
};

export const validateMinMaxDate = (minDate, maxDate) => {
  return compareDesc(parseISO(minDate?.value), parseISO(maxDate?.value));
};

export const validatePhoneNumber = (inputText) => {
  const validRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return validRegex.test(inputText);
};

export const validateName = (inputText) => {
  return inputText.length > 1 ? '' : 'Please enter a value';
};

export const validateNumber = (inputText) => {
  return isNaN(inputText) ? 'Please enter a valid number' : '';
};

export const validateNumberLength = (inputText, requiredLength) => {
  return inputText.length !== requiredLength
    ? `This field should be ${requiredLength} characters long`
    : '';
};

export const convertLangToCulture = (lang) => {
  return lang === 'en' ? 'en-GB' : 'nl-NL';
};

export const checkForErrors = (fields, requiredFields) => {
  let hasErrors = false;
  for (let i = 0; i < requiredFields.length; i++) {
    if (!fields[requiredFields[i]]) {
      hasErrors = true;
    } else {
      if (fields[requiredFields[i]].value.length === 0) {
        hasErrors = true;
      }
    }
    return hasErrors;
  }
};
