import './number-stepper.scss';

export const NumberStepper = ({
  currentValue,
  setCurrentValue,
  isDisabled,
}) => {
  return (
    <div className="number-stepper-container">
      <input
        onChange={(event) => setCurrentValue(event.target.value)}
        disabled={isDisabled}
        value={currentValue}
        className={'number-stepper-control'}
        type="number"
      />
    </div>
  );
};
