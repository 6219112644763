import './tabs-accordion.scss';
import React, { useEffect, useState, forwardRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { getMarkdownText } from '../../services/helper.service';

export const TabsAccordion = ({ data }) => {
  const [tabs, setTabs] = useState([]);
  const [tabsData, setTabsData] = useState(data.attributes.tabs);
  const [tabsWidth, setTabsWidth] = useState(0);
  const [tabHeaders, setTabHeaders] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  useEffect(() => {
    tabsWidthLogic();
  }, [tabsData]);

  const tabsWidthLogic = () => {
    setTabsWidth(
      Array.from(document.getElementsByClassName('react-tabs__tab'))
        .map((e) => e.getBoundingClientRect().width)
        .reduce((a, b) => a + b, 0) + 200 // counting padding
    );
  };

  useEffect(() => {
    setTabsData(data.attributes.tabs);
  }, [data]);

  useEffect(() => {
    const mapTabs = () => {
      setTabs(tabsData.map((t) => t.content));
      setTabHeaders(
        tabsData.map((e) => {
          return { header: e.header, icon: e.icon };
        })
      );
    };
    mapTabs();

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [tabsData]);

  return (
    <div>
      {tabs && tabsWidth < dimensions.width && (
        <Tabs domRef={() => tabsWidthLogic()}>
          <TabList>
            {tabHeaders.map((header, h) => {
              return (
                <Tab key={h}>
                  {header.icon && <i className={`${header.icon} icon`}></i>}
                  {header.header}{' '}
                </Tab>
              );
            })}
          </TabList>
          {tabs.map((tab, t) => {
            return (
              <TabPanel
                key={t}
                dangerouslySetInnerHTML={getMarkdownText(tab)}
              ></TabPanel>
            );
          })}
        </Tabs>
      )}
      {tabs && tabsWidth > dimensions.width && (
        <Accordion allowZeroExpanded>
          {tabsData.map((item, r) => {
            return (
              <AccordionItem key={r}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {item.icon && <i className={`${item.icon} icon`}></i>}
                    {item.header}{' '}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel
                  dangerouslySetInnerHTML={getMarkdownText(item.content)}
                ></AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      )}
    </div>
  );
};
