import "./badge.scss";

export const Badge = ({ text, size }) => {
  const category = {
    news: "News",
    pressrelease: "Press release",
  };

  return (
    <div className={"badge rounded font-bold px-2 " + size}>
      {category[text]}
    </div>
  );
};
