export const homeNewsItems = [
  {
    heading: "Dignissimos ducimus",
    snippetText:
      "Dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos",
  },
  {
    heading: "Saepe eveniet",
    snippetText:
      "Saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae",
  },
  {
    heading: "Eos et accusamus et iusto",
    snippetText:
      "Eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque",
  },
  {
    heading: "Quibusdam et aut officiis",
    snippetText:
      "Quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae",
  },
  {
    heading: "Voluptatibus maiores",
    snippetText:
      "Voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.",
  },
  {
    heading: "Molestias excepturi sint",
    snippetText:
      "Molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt",
  },
];
