import './menu-bar-item-parent.scss';
import ArrowDown from '../../arrow-down/arrow-down';
import ArrowUp from '../../arrow-up/arrow-up';
import MenuBarItem from '../menu-bar-item/menu-bar-item';
import { useState } from 'react';

export const MenuBarItemParent = ({
  selectedIndex,
  myIndex,
  menu,
  handleParentItemClicked,
}) => {
  const handleClicked = () => {
    handleParentItemClicked(selectedIndex === myIndex ? -1 : myIndex);
  };

  return (
    <div className="menu-bar-item-parent" onClick={() => handleClicked()}>
      <div className="menu-bar-item-parent-container">
        <div to={menu.linkRef} className="menu-bar-item-text noselect">
          {menu.description}
        </div>
        {selectedIndex === myIndex ? <ArrowUp /> : <ArrowDown />}
      </div>
      <div className="menu-bar-item-parent-children">
        {selectedIndex === myIndex &&
          menu.children.map((childItem) => {
            return childItem.menuAttached && (
              <MenuBarItem
                key={childItem.linkRef}
                menuItem={{
                  description: childItem.description,
                  linkRef: childItem.linkRef,
                  menuAttached: childItem.menuAttached,
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default MenuBarItemParent;
