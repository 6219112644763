import { ApiEnum, HttpGetWithSlug } from '../../services/http.service'
import React, { useEffect, useState, useRef } from 'react'
import { SingleAirline } from '../../../screens/single-airline/single-airline'
import { GenericPage } from '../../../screens/generic-page/generic-page'
import { SingleNews } from '../../../screens/single-news/single-news'
import { pageTypes } from '../../enums/page-types'

export const PageLoader = ({path,slug, pageType, isAirline, breadcrumbPath}) => {
  const [newPath, setNewPath] = useState(path ?? window.location.pathname.split('/').pop());
  const [page, setPage] = useState([])
  const [airline, setAirline] = useState([])
  const [post, setPost] = useState([])
  const countRef = useRef(0)

  useEffect(() => {    
    setNewPath(path)
  },[path])

  useEffect(() => {
    const getAirline = async () => {
      HttpGetWithSlug(ApiEnum.AIRLINES, newPath, [
        'banner_desktop, banner_mobile, image, tabs, localizations.image, localizations.banner_desktop, localizations.banner_desktop, localizations.tabs',
      ]).then((res) => {
        if (res && res[0]) {
          setAirline(res)
          countRef.current++
        }
      })
    }

    const getPage = async () => {
      HttpGetWithSlug(ApiEnum.PAGES, slug, [
        'header_image',
        'localizations',
        'localizations.header_image',
        'tabs',
        'localizations.tabs',
      ]).then((res) => {
        if (res && res[0]) {
          setPage(res)
          countRef.current++
        }
      })
    }

    const getPost = async () => {
      HttpGetWithSlug(ApiEnum.POSTS, newPath, ['image, localizations.image']).then(
        (res) => {
          if (res && res[0]) {
            setPost(res)
            countRef.current++
          }
        }
      )
    }
    if (countRef.current < 3) {
      getPage()
      getPost()
      getAirline()
    }
  }, [newPath, slug])

  return (
    <>
      {pageType === pageTypes.generic && page && page.length > 0 && <GenericPage breadcrumbPath={breadcrumbPath} urlPath={newPath} slug={slug} />}
      {pageType === pageTypes.post && post && post.length > 0 && <SingleNews />}
      {pageType === pageTypes.airline && airline && airline.length > 0 && <SingleAirline />}
    </>
  )
}
