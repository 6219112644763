import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './checkbox.scss';

export const Checkbox = ({
  fieldName,
  isRequired,
  children,
  onValueChanged,
  showRequired,
  fieldValues,
  storybookValue,
  isManaged,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(getCurrentValue());
  }, [fieldValues]);

  const getCurrentValue = () => {
    if (isManaged && fieldValues) {
      if (Object.keys(fieldValues).length > 0) {
        if (fieldValues[fieldName]) {
          return fieldValues[fieldName].value;
        }
      }
      return false;
    }
  };

  const handleCheckboxClicked = () => {
    onValueChanged(
      fieldName,
      !getCurrentValue(),
      getCurrentValue()
        ? ''
        : isRequired
        ? 'Please ensure you have agreed to the above'
        : ''
    );
  };

  return (
    <div className="checkbox-container">
      <div
        className="checkbox-control-container"
        onClick={handleCheckboxClicked}
      >
        {(isChecked || storybookValue) && (
          <FontAwesomeIcon icon={faCheck} className="checkbox-icon" />
        )}
      </div>
      <div
        className={`checkbox-children  ${
          showRequired && isRequired && !isChecked
            ? 'failed-validation-text'
            : ''
        }`}
      >
        {children}
      </div>
    </div>
  );
};
