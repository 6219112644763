import "./footer.scss";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiEnum, HttpGetWithPopulation } from "../../services/http.service";
import { serveImage } from "../../services/image.sevice";
import { getMarkdownText } from "../../services/helper.service";

export const Footer = () => {
  const [content, setContent] = useState([]);

  const getContent = async () => {
    HttpGetWithPopulation(ApiEnum.FOOTERS, [
      "upper_footer",
      "footer_link",
      "middle_section",
      "footer_logos",
      "footer_logos.logo",
    ]).then((res) => {
      setContent(res.data);
    });
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getContent();
  }, []);

  return (
    <div className="footer-container">
      {content.map((item, i) => {
        return (
          <div className="footer-content" key={i}>
            <div className="upper-section">
              {item.attributes.upper_footer.map((box, k) => {
                return (
                  <div key={k} className="box">
                    {box.icon && <i className={`${box.icon} icon`}></i>}
                    <div
                      className="content"
                      dangerouslySetInnerHTML={getMarkdownText(box.content)}
                    />
                  </div>
                );
              })}
            </div>
            <div className="middle-section">
              {item.attributes.middle_section.data.map((middle, j) => {
                return (
                  <img
                    className="object-cover"
                    key={j}
                    height="30"
                    width="200"
                    src={serveImage({ data: middle }, "medium", "")}
                    alt={middle ? middle.attributes.alternativeText : ""}
                  />
                );
              })}
            </div>
            <div className="lower-section">
              <div className="footer-links">
                {item.attributes.footer_link.map((link, l) => {
                  return (
                    <div
                      key={l}
                      className="link"
                      dangerouslySetInnerHTML={getMarkdownText(link.link)}
                    />
                  );
                })}
              </div>
              <div className="footer-logos">
                {item.attributes.footer_logos.map((logo, m) => {
                  return (
                    <a
                      href={logo.link ? logo.link : "#"}
                      target="_blank"
                      rel="noreferrer"
                      key={m}
                    >
                      <img
                        className="object-cover"
                        height="30"
                        width="200"
                        src={serveImage(logo.logo, "small", "")}
                        alt={
                          logo.logo.data
                            ? logo.logo.data.attributes.alternativeText
                            : ""
                        }
                      />
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
