import './header.scss';
import { breakpoint } from '../../../App';
import { LanguageSelector } from '../language-selector/language-selector';
import { Link } from 'react-router-dom';
import { Logout } from '../logout-button/logout-button';
import { MenuBar } from '../menu-bar/menu-bar';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/logo.png';
import SearchBox from '../search-box/search-box';

export const Header = ({ navigationPaths }) => {
  const { t } = useTranslation();
  return (
    <div className="header-wrapper">
      <div className="header-container">
        <div className="header-logo-container">
          <Link to="/">
            <img src={logo} className="header-logo" alt="logo" />
          </Link>
        </div>
        {breakpoint?.xl && navigationPaths && (
          <MenuBar navigationPaths={navigationPaths} />
        )}
        {breakpoint?.md ? (
          <SearchBox
            className="from-header"
            placeholder={'Zoek nieuws…'}
            showButton={true}
          />
        ) : (
          ''
        )}

        {breakpoint?.xl ? (
          <div className="news-link">
            <Link to="/news">{t('News')}</Link>
          </div>
        ) : (
          ''
        )}
        {breakpoint?.xl ? <Logout /> : ''}
      </div>
    </div>
  );
};
