import { Footer } from '../../../common/components/footer/footer'
import '../../../common/services/http.service'
import React, { useEffect, useState } from 'react'
import {
  ApiEnum,
  HttpGetWithPagination,
} from '../../../common/services/http.service'
import { NewsListItem } from '../news-list-item/news-list-item'
import { Link, useSearchParams } from 'react-router-dom'
import './news-list.scss'
import { Breadcrumbs } from '../../../common/components/breadcrumbs/breadcrumbs'

export const NewsListPage = () => {
  const [posts, setPosts] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(searchParams.get('page'))

  const getPosts = async (param) => {
    if (param) {
      HttpGetWithPagination(ApiEnum.POSTS, param, 16).then((res) => {
        setPosts(res)
      })
    } else {
      HttpGetWithPagination(ApiEnum.POSTS, page ? page : 1, 16).then((res) => {
        setPosts(res)
      })
    }
  }

  const setCurrentPage = (pageNumber) => {
    setSearchParams(`page=${pageNumber}`)
    setPage()
    getPosts(pageNumber)
  }

  const setPagination = (direction) => {
    const totalPages = posts.meta.pagination.pageCount
    const currentPage = parseInt(searchParams.get('page'))
    switch (direction) {
      case 'start':
        setCurrentPage(1)
        break
      case 'end':
        setCurrentPage(totalPages)
        break
      case 'forward':
        setCurrentPage(
          currentPage + 1 >= totalPages ? totalPages : currentPage + 1
        )
        break
      case 'backwards':
        setCurrentPage(currentPage - 1 < 1 ? 1 : currentPage - 1)
        break
      default:
        break
    }
  }

  useEffect(() => {
    getPosts()
  }, [])

  return (
    <div className="news-list-container">
      <div className="news-list-body-container">
        <Breadcrumbs
          stylingClass="pt-2 ml-50-px w-full"
          currentItemTitle="News"
          currentItemUrl="/news"
        />
        {posts.data &&
          posts.data.map((item, i) => {
            return (
              <Link key={i} to={item.attributes.slug}>
                <NewsListItem
                  date={item.attributes.date}
                  heading={item.attributes.title}
                  image={item.attributes.image}
                  category={item.attributes.category}
                />
              </Link>
            )
          })}
      </div>
      <div className="pagination-container">
        {posts.meta && (
          <div className="pagination">
            <button onClick={() => setPagination('start')}>«</button>
            <button onClick={() => setPagination('backwards')}>‹</button>

            {[...Array(posts.meta.pagination.pageCount)].map((x, i) => (
              <button
                key={i}
                className={
                  i + 1 === posts.meta.pagination.page
                    ? 'active pageButton'
                    : 'pageButton'
                }
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </button>
            ))}

            <button onClick={() => setPagination('forward')}>›</button>
            <button onClick={() => setPagination('end')}>»</button>
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}
