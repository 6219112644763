import './menu-bar-item.scss';
import { Link } from 'react-router-dom';

export const MenuBarItem = ({ menuItem, isExpanded }) => {
  return menuItem.menuAttached && (
    <div className="menu-bar-item" >
      <Link to={menuItem.linkRef} className="menu-bar-item-link noselect">
        {menuItem.description}
      </Link>
    </div>
  );
};

export default MenuBarItem;
