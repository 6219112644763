import './airlines.scss'
import React, {useEffect, useState, useReducer} from 'react'
import {Carousel} from '../../../common/components/carousel/carousel'
import {homeCarouselItems} from '../../home/home-carousel-items'
import SearchBox from '../../../common/components/search-box/search-box'
import {AirlineListItem} from '../airline-list-item/airline-list-item'
import {
  ApiEnum,
  HttpGetWithSorting,
} from '../../../common/services/http.service'
import {useTranslation} from 'react-i18next'
import {Breadcrumbs} from '../../../common/components/breadcrumbs/breadcrumbs'

export const AirlinesListPage = () => {
  const dataFetchReducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_INIT':
        return {...state, isData: false}
      case 'FETCH_SUCCESS':
        return {
          ...state,
          isData: true,
          data: action.payload,
          initData: action.initData,
        }
      case 'FETCH_FAILURE':
        return {...state, isData: false}
      case 'FILTERED':
        return {
          ...state,
          isData: true,
          data: action.payload,
        }
      default:
        throw new Error()
    }
  }

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isData: false,
    data: null,
    initData: null,
  })

  const saveTextInputHandler = (searchFieldText) => {
    setSearchFieldText(searchFieldText);
  }

  useEffect(() => {
    document.body.classList.add('blue')
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' })
      try {
        const result = await HttpGetWithSorting(ApiEnum.AIRLINES, 'name:asc')
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: result.data,
          initData: result.data,
        })
      } catch (error) {
        dispatch({ type: 'FETCH_FAILURE' })
      }
    }
    fetchData()
  }, [])

  const [searchFieldText, setSearchFieldText] = useState('');

  useEffect(() => {
    if (state.isData) {
      const filtered = state?.initData?.filter((airline) =>
        airline.attributes.name.toLocaleLowerCase()
          .includes(searchFieldText.toLocaleLowerCase()))

      dispatch({
        type: 'FILTERED',
        payload: filtered,
      })
      if (searchFieldText.length === 0) {
        dispatch({
          type: 'FILTERED',
          payload: state.initData,
        })
      }
    }
  }, [searchFieldText])

  const {t, i18n} = useTranslation()

  return (
    <div className="airlines-container">
      <Carousel items={homeCarouselItems}/>
      <div className="airlines-body-container">
        <Breadcrumbs
          stylingClass="pt-2 w-full"
          currentItemTitle="Airlines"
          currentItemUrl="/airlines"
        />
        <p className="page-heading mt-6">{t('Airlines')}</p>
        <SearchBox
          placeholder={t('Search airlines')}
          onSaveTextInput={saveTextInputHandler}
          showButton={true}
          disableButton={true}
        />
        <div className="airline-boxes">
          {state.data &&
          state.data.map((airline, index) => (
            <AirlineListItem
              key={index}
              airlineImage={airline.attributes.image}
              airlineSlug={airline.attributes.slug}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
