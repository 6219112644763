const ArrowUp = () => {
  return (
    <>
      <svg
        width="14"
        height="8"
        viewBox="0 0 18 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.3909 10.4211L18 8.83155L9 0L1.5714e-07 8.84048L1.60909 10.4211L9 3.16114L16.3909 10.4211Z"
          fill="black"
        />
      </svg>
    </>
  );
};
export default ArrowUp;