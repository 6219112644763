import './menu-bar.scss';
import MenuBarItemParent from './menu-bar-item-parent/menu-bar-item-parent';
import { useEffect, useState } from 'react';
import { menuItemLimit } from '../../constants/general';

export const MenuBar = ({ navigationPaths }) => {
  const [dynamicMenu, setDynamicMenu] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (navigationPaths) {
      const menuItems = navigationPaths.map((menuItem) => ({
        description: menuItem.title,
        linkRef: menuItem.path,
        menuAttached: menuItem.menuAttached,
        children: menuItem.items?.map((childItem) => ({
          description: childItem.title,
          linkRef: childItem.path,
          menuAttached: childItem.menuAttached,
        })),
      }));
      setDynamicMenu(menuItems);
    }
  }, [navigationPaths]);

  const handleParentItemClicked = (itemIndex) => {
    setSelectedIndex(itemIndex);
  };

  return dynamicMenu.map((menuItem, menuItemIndex) => {
    return (
      menuItemIndex < menuItemLimit &&
      menuItem?.menuAttached && (
        <MenuBarItemParent
          key={menuItemIndex}
          selectedIndex={selectedIndex}
          myIndex={menuItemIndex}
          menu={menuItem}
          handleParentItemClicked={handleParentItemClicked}
        />
      )
    );
  });
};
