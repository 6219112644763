export const airlinesList = [
  {
    $id: "1",
    Code: "JP",
    DisplayName: "Adria Airways",
  },
  {
    $id: "2",
    Code: "A3",
    DisplayName: "Aegean Airlines",
  },
  {
    $id: "3",
    Code: "EI",
    DisplayName: "Aer Lingus",
  },
  {
    $id: "4",
    Code: "AM",
    DisplayName: "Aero Mexico",
  },
  {
    $id: "6",
    Code: "AR",
    DisplayName: "Aerolinas Argentinas",
  },
  {
    $id: "7",
    Code: "8U",
    DisplayName: "Afriqiyah",
  },
  {
    $id: "8",
    Code: "AH",
    DisplayName: "Air Algerie",
  },
  {
    $id: "9",
    Code: "3O",
    DisplayName: "Air Arabia Maroc",
  },
  {
    $id: "10",
    Code: "BT",
    DisplayName: "Air Baltic",
  },
  {
    $id: "11",
    Code: "AC",
    DisplayName: "Air Canada",
  },
  {
    $id: "12",
    Code: "CA",
    DisplayName: "Air China",
  },
  {
    $id: "13",
    Code: "UX",
    DisplayName: "Air Europa",
  },
  {
    $id: "14",
    Code: "AF",
    DisplayName: "Air France",
  },
  {
    $id: "15",
    Code: "AI",
    DisplayName: "Air India",
  },
  {
    $id: "16",
    Code: "IG",
    DisplayName: "Air Italy",
  },
  {
    $id: "17",
    Code: "MD",
    DisplayName: "Air Madagascar",
  },
  {
    $id: "18",
    Code: "KM",
    DisplayName: "Air Malta",
  },
  {
    $id: "19",
    Code: "MK",
    DisplayName: "Air Mauritius",
  },
  {
    $id: "21",
    Code: "NZ",
    DisplayName: "Air New Zealand",
  },
  {
    $id: "22",
    Code: "RS",
    DisplayName: "Air Seoul",
  },
  {
    $id: "23",
    Code: "HM",
    DisplayName: "Air Seychelles",
  },
  {
    $id: "24",
    Code: "VT",
    DisplayName: "Air Tahiti",
  },
  {
    $id: "25",
    Code: "TN",
    DisplayName: "Air Tahiti Nui",
  },
  {
    $id: "26",
    Code: "TS",
    DisplayName: "Air Transat",
  },
  {
    $id: "27",
    Code: "AK",
    DisplayName: "AirAsia",
  },
  {
    $id: "28",
    Code: "I5",
    DisplayName: "AirAsia India",
  },
  {
    $id: "29",
    Code: "SZ",
    DisplayName: "Aircompany Somon Air",
  },
  {
    $id: "30",
    Code: "AS",
    DisplayName: "Alaska Airlines",
  },
  {
    $id: "31",
    Code: "AZ",
    DisplayName: "Alitalia",
  },
  {
    $id: "32",
    Code: "NH",
    DisplayName: "All Nippon Airways",
  },
  {
    $id: "33",
    Code: "AA",
    DisplayName: "American Airlines",
  },
  {
    $id: "34",
    Code: "OS",
    DisplayName: "Austrian Airlines",
  },
  {
    $id: "35",
    Code: "AV",
    DisplayName: "Avianca",
  },
  {
    $id: "36",
    Code: "O6",
    DisplayName: "Avianca Brasil",
  },
  {
    $id: "37",
    Code: "J2",
    DisplayName: "Azerbaijan Airlines",
  },
  {
    $id: "38",
    Code: "UP",
    DisplayName: "Bahamasair",
  },
  {
    $id: "39",
    Code: "PG",
    DisplayName: "Bangkok Airways",
  },
  {
    $id: "41",
    Code: "SI",
    DisplayName: "Blue Islands",
  },
  {
    $id: "42",
    Code: "BA",
    DisplayName: "British Airways",
  },
  {
    $id: "43",
    Code: "SN",
    DisplayName: "Brussels Airlines",
  },
  {
    $id: "44",
    Code: "FB",
    DisplayName: "Bulgaria Air",
  },
  {
    $id: "45",
    Code: "QD",
    DisplayName: "Cambodia International Airlines",
  },
  {
    $id: "46",
    Code: "BW",
    DisplayName: "Caribbean Airlines",
  },
  {
    $id: "47",
    Code: "CX",
    DisplayName: "Cathay Pacific",
  },
  {
    $id: "48",
    Code: "5Z",
    DisplayName: "Cemair",
  },
  {
    $id: "49",
    Code: "CE",
    DisplayName: "Chalair Aviation",
  },
  {
    $id: "50",
    Code: "CI",
    DisplayName: "China Airlines",
  },
  {
    $id: "51",
    Code: "CZ",
    DisplayName: "China Southern Airlines",
  },
  {
    $id: "52",
    Code: "QG",
    DisplayName: "Citilink",
  },
  {
    $id: "53",
    Code: "WX",
    DisplayName: "Cityjet",
  },
  {
    $id: "54",
    Code: "DE",
    DisplayName: "Condor",
  },
  {
    $id: "55",
    Code: "CM",
    DisplayName: "Copa Airlines",
  },
  {
    $id: "56",
    Code: "CR",
    DisplayName: "Corendon",
  },
  {
    $id: "57",
    Code: "OU",
    DisplayName: "Croatia Airlines",
  },
  {
    $id: "58",
    Code: "OK",
    DisplayName: "CSA Czech Airlines",
  },
  {
    $id: "59",
    Code: "DL",
    DisplayName: "Delta Air Lines",
  },
  {
    $id: "60",
    Code: "KA",
    DisplayName: "Dragonair",
  },
  {
    $id: "61",
    Code: "T3",
    DisplayName: "Eastern Airways",
  },
  {
    $id: "62",
    Code: "U2",
    DisplayName: "easyJet",
  },
  {
    $id: "63",
    Code: "MS",
    DisplayName: "Egyptair",
  },
  {
    $id: "64",
    Code: "LY",
    DisplayName: "EL AL",
  },
  {
    $id: "65",
    Code: "EK",
    DisplayName: "Emirates",
  },
  {
    $id: "66",
    Code: "OV",
    DisplayName: "Estonian Air",
  },
  {
    $id: "67",
    Code: "ET",
    DisplayName: "Ethiopian Airlines",
  },
  {
    $id: "68",
    Code: "EY",
    DisplayName: "Etihad Airways",
  },
  {
    $id: "69",
    Code: "EW",
    DisplayName: "Eurowings",
  },
  {
    $id: "70",
    Code: "BR",
    DisplayName: "EVA Air",
  },
  {
    $id: "71",
    Code: "AY",
    DisplayName: "Finnair",
  },
  {
    $id: "72",
    Code: "8W",
    DisplayName: "Fly Allways",
  },
  {
    $id: "73",
    Code: "F3",
    DisplayName: "Flyadeal",
  },
  {
    $id: "74",
    Code: "BE",
    DisplayName: "FlyBE",
  },
  {
    $id: "75",
    Code: "GA",
    DisplayName: "Garuda Indonesia",
  },
  {
    $id: "76",
    Code: "4U",
    DisplayName: "Germanwings",
  },
  {
    $id: "77",
    Code: "GF",
    DisplayName: "Gulf Air",
  },
  {
    $id: "78",
    Code: "HU",
    DisplayName: "Hainan Air",
  },
  {
    $id: "79",
    Code: "HA",
    DisplayName: "Hawaiian Airlines",
  },
  {
    $id: "80",
    Code: "MR",
    DisplayName: "Hunnu Air",
  },
  {
    $id: "81",
    Code: "IB",
    DisplayName: "Iberia",
  },
  {
    $id: "82",
    Code: "FI",
    DisplayName: "Icelandair",
  },
  {
    $id: "83",
    Code: "IC",
    DisplayName: "Indian Airlines",
  },
  {
    $id: "84",
    Code: "6E",
    DisplayName: "Indigo Airlines",
  },
  {
    $id: "85",
    Code: "IR",
    DisplayName: "Iran Air",
  },
  {
    $id: "86",
    Code: "JL",
    DisplayName: "Japan Airlines",
  },
  {
    $id: "87",
    Code: "7C",
    DisplayName: "Jeju Air",
  },
  {
    $id: "88",
    Code: "9W",
    DisplayName: "Jet Airways",
  },
  {
    $id: "89",
    Code: "LS",
    DisplayName: "Jet2.com",
  },
  {
    $id: "90",
    Code: "JN",
    DisplayName: "Joon",
  },
  {
    $id: "91",
    Code: "KQ",
    DisplayName: "Kenya Airways",
  },
  {
    $id: "92",
    Code: "KL",
    DisplayName: "KLM",
  },
  {
    $id: "93",
    Code: "KE",
    DisplayName: "Korean Air",
  },
  {
    $id: "94",
    Code: "MN",
    DisplayName: "Kulula",
  },
  {
    $id: "95",
    Code: "KU",
    DisplayName: "Kuwait Airways",
  },
  {
    $id: "96",
    Code: "LA",
    DisplayName: "Lan Airlines",
  },
  {
    $id: "97",
    Code: "LP",
    DisplayName: "LAN Peru",
  },
  {
    $id: "98",
    Code: "JT",
    DisplayName: "Lion Air",
  },
  {
    $id: "99",
    Code: "LO",
    DisplayName: "LOT Polish Airlines",
  },
  {
    $id: "100",
    Code: "LH",
    DisplayName: "Lufthansa",
  },
  {
    $id: "101",
    Code: "LG",
    DisplayName: "Luxair",
  },
  {
    $id: "102",
    Code: "MH",
    DisplayName: "Malaysia Airlines",
  },
  {
    $id: "104",
    Code: "XY",
    DisplayName: "NasAir",
  },
  {
    $id: "105",
    Code: "DD",
    DisplayName: "Nok Air",
  },
  {
    $id: "106",
    Code: "OA",
    DisplayName: "Olympic Air",
  },
  {
    $id: "107",
    Code: "WY",
    DisplayName: "Oman Air",
  },
  {
    $id: "108",
    Code: "EC",
    DisplayName: "OpenSkies",
  },
  {
    $id: "109",
    Code: "PK",
    DisplayName: "Pakistan International Airlines",
  },
  {
    $id: "110",
    Code: "PR",
    DisplayName: "Philippine Airlines",
  },
  {
    $id: "111",
    Code: "PT",
    DisplayName: "Piedmont Airlines",
  },
  {
    $id: "113",
    Code: "QF",
    DisplayName: "Qantas",
  },
  {
    $id: "114",
    Code: "QR",
    DisplayName: "Qatar Airways",
  },
  {
    $id: "115",
    Code: "AT",
    DisplayName: "Royal Air Maroc",
  },
  {
    $id: "116",
    Code: "BI",
    DisplayName: "Royal Brunei Airlines",
  },
  {
    $id: "117",
    Code: "RJ",
    DisplayName: "Royal Jordanian",
  },
  {
    $id: "118",
    Code: "FR",
    DisplayName: "Ryanair",
  },
  {
    $id: "119",
    Code: "SK",
    DisplayName: "SAS Scandinavian Airlines",
  },
  {
    $id: "120",
    Code: "SV",
    DisplayName: "Saudi Arabian Airlines",
  },
  {
    $id: "122",
    Code: "SQ",
    DisplayName: "Singapore Airlines",
  },
  {
    $id: "123",
    Code: "NK",
    DisplayName: "Spirit Airlines",
  },
  {
    $id: "124",
    Code: "UL",
    DisplayName: "Srilankan Airlines",
  },
  {
    $id: "125",
    Code: "XQ",
    DisplayName: "SunExpress",
  },
  {
    $id: "126",
    Code: "PY",
    DisplayName: "Surinam Airways",
  },
  {
    $id: "127",
    Code: "LX",
    DisplayName: "Swiss International Air Lines",
  },
  {
    $id: "128",
    Code: "RB",
    DisplayName: "Syrian Air",
  },
  {
    $id: "130",
    Code: "JJ",
    DisplayName: "TAM Airlines",
  },
  {
    $id: "131",
    Code: "TP",
    DisplayName: "TAP Portugal",
  },
  {
    $id: "132",
    Code: "RO",
    DisplayName: "Tarom",
  },
  {
    $id: "133",
    Code: "TG",
    DisplayName: "Thai Airways International",
  },
  {
    $id: "134",
    Code: "SL",
    DisplayName: "Thai Lion Air",
  },
  {
    $id: "135",
    Code: "BY",
    DisplayName: "Thomson Airways",
  },
  {
    $id: "136",
    Code: "IT",
    DisplayName: "Tigerair Taiwan",
  },
  {
    $id: "137",
    Code: "HV",
    DisplayName: "Transavia",
  },
  {
    $id: "138",
    Code: "OR",
    DisplayName: "TUI",
  },
  {
    $id: "139",
    Code: "TU",
    DisplayName: "Tunisair",
  },
  {
    $id: "140",
    Code: "TK",
    DisplayName: "Turkish Airlines",
  },
  {
    $id: "141",
    Code: "PS",
    DisplayName: "Ukraine International Airlines",
  },
  {
    $id: "142",
    Code: "UA",
    DisplayName: "United Airlines",
  },
  {
    $id: "143",
    Code: "VN",
    DisplayName: "Vietnam Airlines",
  },
  {
    $id: "144",
    Code: "VS",
    DisplayName: "Virgin Atlantic Airways",
  },
  {
    $id: "145",
    Code: "UK",
    DisplayName: "Vistara",
  },
  {
    $id: "146",
    Code: "VG",
    DisplayName: "VLM",
  },
  {
    $id: "147",
    Code: "V7",
    DisplayName: "Volotea",
  },
  {
    $id: "148",
    Code: "VY",
    DisplayName: "Vueling",
  },
  {
    $id: "149",
    Code: "W6",
    DisplayName: "Wizzair",
  },
  {
    $id: "150",
    Code: "WW",
    DisplayName: "WOW Air",
  },
  {
    $id: "151",
    Code: "MF",
    DisplayName: "Xiamen Airlines",
  },
  {
    $id: "152",
    Code: "SE",
    DisplayName: "XL Airways",
  },
];
